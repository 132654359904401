export default {
  "answer_bot_embeddable": {
    "button": {
      "submitting": {
        "af": "Submitting...",
        "ar": "جارٍ الإرسال...",
        "ar-x-pseudo": "[ผู้龍Ṣṵṵḅṃḭḭṭṭḭḭṇḡ...龍ผู้]",
        "az": "Submitting...",
        "be": "Отправка...",
        "bg": "Изпраща се...",
        "bn": "Submitting...",
        "bs": "Submitting...",
        "ca": "Enviando...",
        "cs": "Odesílání...",
        "cy": "Submitting...",
        "da": "Indsender ...",
        "de": "Einreichen...",
        "de-de": "Einreichen...",
        "de-x-informal": "Einreichen...",
        "el": "Γίνεται υποβολή...",
        "en-001": "Submitting...",
        "en-150": "Submitting...",
        "en-US": "Submitting...",
        "en-au": "Submitting...",
        "en-ca": "Submitting...",
        "en-gb": "Submitting...",
        "en-my": "Submitting...",
        "en-ph": "Submitting...",
        "en-se": "Submitting...",
        "en-x-dev": "Submitting...",
        "en-x-keys": "answer_bot_embeddable.button.submitting",
        "en-x-obsolete": "Submitting...",
        "en-x-pseudo": "[ผู้龍Ṣṵṵḅṃḭḭṭṭḭḭṇḡ...龍ผู้]",
        "en-x-test": "Submitting...",
        "es": "Enviando...",
        "es-419": "Enviando...",
        "es-es": "Enviando...",
        "et": "Submitting...",
        "eu": "Enviando...",
        "fa": "Submitting...",
        "fa-AF": "Submitting...",
        "fi": "Lähettää...",
        "fil": "Isinusumite...",
        "fo": "Submitting...",
        "fr": "Envoi en cours...",
        "fr-ca": "Envoi...",
        "ga": "Submitting...",
        "he": "שולח...",
        "hi": "सबमिट किया जा रहा है...",
        "hr": "Submitting...",
        "hu": "Beküldés…",
        "hy": "Submitting...",
        "id": "Sedang mengirim...",
        "is": "Submitting...",
        "it": "Invio...",
        "it-ch": "Invio...",
        "ja": "送信中...",
        "ka": "Submitting...",
        "kk": "Submitting...",
        "kl-dk": "Submitting...",
        "ko": "제출 중...",
        "ku": "Submitting...",
        "lt": "Submitting...",
        "lv": "Submitting...",
        "mk": "Submitting...",
        "mn": "Submitting...",
        "ms": "Menyerah...",
        "mt": "Submitting...",
        "my": "Submitting...",
        "nl": "Verzenden...",
        "nl-be": "Verzenden...",
        "no": "Sender inn...",
        "pl": "Wysyłanie...",
        "pt": "Enviando...",
        "pt-br": "Enviando...",
        "ro": "Se trimite...",
        "ru": "Отправка...",
        "sk": "Odosielanie...",
        "sl": "Submitting...",
        "sq": "Submitting...",
        "sr": "Submitting...",
        "sr-me": "Submitting...",
        "sv": "Skickar...",
        "th": "กำลังส่ง...",
        "tr": "Gönderiliyor...",
        "uk": "Надсилається…",
        "ur": "Submitting...",
        "uz": "Submitting...",
        "vi": "Đang gửi...",
        "zh-cn": "正在提交……",
        "zh-tw": "提交中……"
      }
    },
    "irrelevant": {
      "related_no_answer": {
        "af": "It's related but didn't help",
        "ar": "المقال يتعلق بالسؤال لكنه لم يساعد في الإجابة",
        "ar-x-pseudo": "[ผู้龍ḬḬṭ'ṡ ṛḛḛḽααṭḛḛḍ ḅṵṵṭ ḍḭḭḍṇ'ṭ ḥḛḛḽṗ龍ผู้]",
        "az": "It's related but didn't help",
        "be": "Относится, но не помогает",
        "bg": "Има връзка, но не помогна",
        "bn": "It's related but didn't help",
        "bs": "It's related but didn't help",
        "ca": "Está relacionado, pero no ayuda",
        "cs": "Je relevantní, ale nepomohl",
        "cy": "It's related but didn't help",
        "da": "Det er relateret, men det hjalp ikke",
        "de": "Er hat zwar damit zu tun, half aber nicht weiter",
        "de-de": "Er hat zwar damit zu tun, half aber nicht weiter",
        "de-x-informal": "Er hat zwar damit zu tun, half aber nicht weiter",
        "el": "Σχετίζεται αλλά δεν βοήθησε",
        "en-001": "It's related but didn't help",
        "en-150": "It's related but didn't help",
        "en-US": "It's related but didn't help",
        "en-au": "It's related but didn't help",
        "en-ca": "It's related but didn't help",
        "en-gb": "It's related but didn't help",
        "en-my": "It's related but didn't help",
        "en-ph": "It's related but didn't help",
        "en-se": "It's related but didn't help",
        "en-x-dev": "It's related but didn't help",
        "en-x-keys": "answer_bot_embeddable.irrelevant.related_no_answer",
        "en-x-obsolete": "It's related but didn't help",
        "en-x-pseudo": "[ผู้龍ḬḬṭ'ṡ ṛḛḛḽααṭḛḛḍ ḅṵṵṭ ḍḭḭḍṇ'ṭ ḥḛḛḽṗ龍ผู้]",
        "en-x-test": "It's related but didn't help",
        "es": "Está relacionado, pero no ayuda",
        "es-419": "Está relacionado, pero no ayuda",
        "es-es": "Está relacionado, pero no ayuda",
        "et": "It's related but didn't help",
        "eu": "Está relacionado, pero no ayuda",
        "fa": "It's related but didn't help",
        "fa-AF": "It's related but didn't help",
        "fi": "Se liittyy siihen, mutta ei auttanut",
        "fil": "It's related but didn't help",
        "fo": "It's related but didn't help",
        "fr": "A un lien mais n’a pas aidé",
        "fr-ca": "A un lien mais n’a pas aidé",
        "ga": "It's related but didn't help",
        "he": "הוא קשור אבל לא עזר",
        "hi": "उससे संबंधित है लेकिन सहायक नहीं है",
        "hr": "It's related but didn't help",
        "hu": "A problémámmal kapcsolatos, de nem segített",
        "hy": "It's related but didn't help",
        "id": "Hal ini berkaitan tetapi tidak membantu",
        "is": "It's related but didn't help",
        "it": "È pertinente ma non mi ha aiutato",
        "it-ch": "È pertinente ma non mi ha aiutato",
        "ja": "関連してはいるが、役に立たなかった",
        "ka": "It's related but didn't help",
        "kk": "It's related but didn't help",
        "kl-dk": "It's related but didn't help",
        "ko": "관련성이 있으나 도움이 되지 않음",
        "ku": "It's related but didn't help",
        "lt": "It's related but didn't help",
        "lv": "It's related but didn't help",
        "mk": "It's related but didn't help",
        "mn": "It's related but didn't help",
        "ms": "It's related but didn't help",
        "mt": "It's related but didn't help",
        "my": "It's related but didn't help",
        "nl": "Het antwoord is gerelateerd maar niet nuttig",
        "nl-be": "Het antwoord is gerelateerd maar niet nuttig",
        "no": "Den handler om samme tema, men hjalp meg ikke",
        "pl": "Jest związany z tematem, ale nie pomógł",
        "pt": "Está relacionado, mas não ajudou",
        "pt-br": "Está relacionado, mas não ajudou",
        "ro": "Are legătură, dar nu m-a ajutat",
        "ru": "Относится, но не помогает",
        "sk": "Súvisí, ale nepomáha",
        "sl": "It's related but didn't help",
        "sq": "It's related but didn't help",
        "sr": "It's related but didn't help",
        "sr-me": "It's related but didn't help",
        "sv": "Den är relaterad, men den hjälpte inte",
        "th": "มีความเกี่ยวข้องแต่ไม่ได้ช่วยอะไร",
        "tr": "İlgili ama yardımcı olmadı",
        "uk": "Це пов'язано, але не допомогло",
        "ur": "It's related but didn't help",
        "uz": "It's related but didn't help",
        "vi": "Có liên quan nhưng không hữu ích",
        "zh-cn": "它是相关的，但没有帮助",
        "zh-tw": "有關但沒有幫助"
      }
    }
  },
  "embeddable_framework": {
    "automaticAnswers": {
      "button": {
        "close": {
          "af": "Close dialog",
          "ar": "إغلاق مربع الحوار",
          "ar-x-pseudo": "[ผู้龍Ḉḽṓṓṡḛḛ ḍḭḭααḽṓṓḡ龍ผู้]",
          "az": "Close dialog",
          "be": "Закрыть диалоговое окно",
          "bg": "Затваряне на диалоговия прозорец",
          "bn": "Close dialog",
          "bs": "Close dialog",
          "ca": "Cerrar cuadro de diálogo",
          "cs": "Zavřít dialog",
          "cy": "Close dialog",
          "da": "Luk dialogboks",
          "de": "Dialogfeld schließen",
          "de-de": "Dialogfeld schließen",
          "de-x-informal": "Dialogfeld schließen",
          "el": "Κλείσιμο πλαισίου διαλόγου",
          "en-001": "Close dialog",
          "en-150": "Close dialog",
          "en-US": "Close dialog",
          "en-au": "Close dialog",
          "en-ca": "Close dialog",
          "en-gb": "Close dialog",
          "en-my": "Close dialog",
          "en-ph": "Close dialog",
          "en-se": "Close dialog",
          "en-x-dev": "Close dialog",
          "en-x-keys": "embeddable_framework.automaticAnswers.button.close",
          "en-x-obsolete": "Close dialog",
          "en-x-pseudo": "[ผู้龍Ḉḽṓṓṡḛḛ ḍḭḭααḽṓṓḡ龍ผู้]",
          "en-x-test": "Close dialog",
          "es": "Cerrar cuadro de diálogo",
          "es-419": "Cerrar cuadro de diálogo",
          "es-es": "Cerrar cuadro de diálogo",
          "et": "Close dialog",
          "eu": "Cerrar cuadro de diálogo",
          "fa": "Close dialog",
          "fa-AF": "Close dialog",
          "fi": "Sulje valintaikkuna",
          "fil": "Close dialog",
          "fo": "Close dialog",
          "fr": "Fermer la boîte de dialogue",
          "fr-ca": "Fermer la boîte de dialogue",
          "ga": "Close dialog",
          "he": "סגור דו-שיח",
          "hi": "संवाद बंद करें",
          "hr": "Close dialog",
          "hu": "Párbeszédpanel bezárása",
          "hy": "Close dialog",
          "id": "Tutup dialog",
          "is": "Close dialog",
          "it": "Chiudi finestra di dialogo",
          "it-ch": "Chiudi finestra di dialogo",
          "ja": "ダイアログを閉じる",
          "ka": "Close dialog",
          "kk": "Close dialog",
          "kl-dk": "Close dialog",
          "ko": "대화 상자 닫기",
          "ku": "Close dialog",
          "lt": "Close dialog",
          "lv": "Close dialog",
          "mk": "Close dialog",
          "mn": "Close dialog",
          "ms": "Close dialog",
          "mt": "Close dialog",
          "my": "Close dialog",
          "nl": "Dialoogvenster sluiten",
          "nl-be": "Dialoogvenster sluiten",
          "no": "Lukk dialog",
          "pl": "Zamknij okno dialogowe",
          "pt": "Fechar caixa de diálogo",
          "pt-br": "Fechar caixa de diálogo",
          "ro": "Închidere dialog",
          "ru": "Закрыть диалоговое окно",
          "sk": "Zavrieť dialóg",
          "sl": "Close dialog",
          "sq": "Close dialog",
          "sr": "Close dialog",
          "sr-me": "Close dialog",
          "sv": "Stäng dialogruta",
          "th": "ปิดกล่องโต้ตอบ",
          "tr": "İletişim kutusunu kapat",
          "uk": "Закрити діалогове вікно",
          "ur": "Close dialog",
          "uz": "Close dialog",
          "vi": "Đóng hộp thoại",
          "zh-cn": "关闭对话框",
          "zh-tw": "關閉對話方塊"
        },
        "solve_v2": {
          "af": "Yes, close my request",
          "ar": "نعم، أغلق هذا الطلب",
          "ar-x-pseudo": "[ผู้龍ŶŶḛḛṡ, ͼḽṓṓṡḛḛ ṃẏẏ ṛḛḛʠṵṵḛḛṡṭ龍ผู้]",
          "az": "Yes, close my request",
          "be": "Да, закрыть мой запрос",
          "bg": "Да, закрийте заявката ми",
          "bn": "Yes, close my request",
          "bs": "Yes, close my request",
          "ca": "Sí, tanca la meva sol·licitud",
          "cs": "Ano, zavřít můj požadavek",
          "cy": "Yes, close my request",
          "da": "Ja, luk anmodningen",
          "de": "Ja, Anfrage schließen",
          "de-de": "Ja, Anfrage schließen",
          "de-x-informal": "Ja, Anfrage schließen",
          "el": "Ναι, να κλείσει αυτό το αίτημα",
          "en-001": "Yes, close my request",
          "en-150": "Yes, close my request",
          "en-US": "Yes, close my request",
          "en-au": "Yes, close my request",
          "en-ca": "Yes, close my request",
          "en-gb": "Yes, close my request",
          "en-my": "Yes, close my request",
          "en-ph": "Yes, close my request",
          "en-se": "Yes, close my request",
          "en-x-dev": "Yes, close my request",
          "en-x-keys": "embeddable_framework.automaticAnswers.button.solve_v2",
          "en-x-obsolete": "Yes, close my request",
          "en-x-pseudo": "[ผู้龍ŶŶḛḛṡ, ͼḽṓṓṡḛḛ ṃẏẏ ṛḛḛʠṵṵḛḛṡṭ龍ผู้]",
          "en-x-test": "Yes, close my request",
          "es": "Sí, cerrar mi solicitud",
          "es-419": "Sí, cerrar mi solicitud",
          "es-es": "Sí, cerrar mi solicitud",
          "et": "Jah, sulge minu päring",
          "eu": "Sí, cerrar mi solicitud",
          "fa": "بله، درخواستم بسته شود",
          "fa-AF": "Yes, close my request",
          "fi": "Kyllä, sulje pyyntöni",
          "fil": "Oo, isara ang kahilingang ito",
          "fo": "Yes, close my request",
          "fr": "Oui, fermer ma demande",
          "fr-ca": "Oui, fermer ma demande",
          "ga": "Yes, close my request",
          "he": "כן, סגרו את הבקשה שלי",
          "hi": "हाँ, इस अनुरोध को बंद करें",
          "hr": "Da, zatvori moj zahtjev",
          "hu": "Igen, zárják le a kérelmemet",
          "hy": "Yes, close my request",
          "id": "Ya, tutup permintaan ini",
          "is": "Já, loka beiðninni minni",
          "it": "Sì, chiudi la richiesta",
          "it-ch": "Sì, chiudi la richiesta",
          "ja": "はい、リクエストを終了",
          "ka": "Yes, close my request",
          "kk": "Yes, close my request",
          "kl-dk": "Yes, close my request",
          "ko": "예, 요청 종료",
          "ku": "Belê, daxwaza me dabixe",
          "lt": "Taip, uždaryti užklausą",
          "lv": "Jā, slēgt manu pieprasījumu",
          "mk": "Yes, close my request",
          "mn": "Yes, close my request",
          "ms": "Ya, tutup permintaan ini",
          "mt": "Yes, close my request",
          "my": "Yes, close my request",
          "nl": "Ja, mijn aanvraag sluiten",
          "nl-be": "Ja, mijn aanvraag sluiten",
          "no": "Ja, avslutt forespørselen",
          "pl": "Tak, zamknij zlecenie",
          "pt": "Sim, feche a solicitação",
          "pt-br": "Sim, feche a solicitação",
          "ro": "Da, închideți solicitarea mea",
          "ru": "Да, закрыть мой запрос",
          "sk": "Áno, uzavrieť moju žiadosť.",
          "sl": "Da, zapri mojo zahtevo",
          "sq": "Po, mbylle kërkesën time",
          "sr": "Da, zatvori moj zahtev",
          "sr-me": "Yes, close my request",
          "sv": "Ja, stäng min förfrågan",
          "th": "ใช่ ปิดคำร้องขอนี้",
          "tr": "Evet, talebimi kapatın",
          "uk": "Так, закрити запит",
          "ur": "ہاں، میری درخواست بند کریں",
          "uz": "Yes, close my request",
          "vi": "Có, đóng yêu cầu này lại",
          "zh-cn": "是，关闭我的请求",
          "zh-tw": "是，關閉我的請求"
        }
      },
      "desktop": {
        "irrelevant": {
          "not_related": {
            "af": "It's not related to my question",
            "ar": "لا علاقة له بسؤالي",
            "ar-x-pseudo": "[ผู้龍ḬḬṭ'ṡ ṇṓṓṭ ṛḛḛḽααṭḛḛḍ ṭṓṓ ṃẏẏ ʠṵṵḛḛṡṭḭḭṓṓṇ龍ผู้]",
            "az": "It's not related to my question",
            "be": "Она не относится к моему вопросу",
            "bg": "Няма връзка с моя въпрос",
            "bn": "It's not related to my question",
            "bs": "It's not related to my question",
            "ca": "No està relacionat amb la meva pregunta",
            "cs": "Nesouvisí s mou otázkou",
            "cy": "It's not related to my question",
            "da": "Det har ingen relation til mit spørgsmål",
            "de": "Er hat nichts mit meiner Frage zu tun",
            "de-de": "Er hat nichts mit meiner Frage zu tun",
            "de-x-informal": "Er hat nichts mit meiner Frage zu tun",
            "el": "Δεν είναι σχετικό με την ερώτησή μου",
            "en-001": "It's not related to my question",
            "en-150": "It's not related to my question",
            "en-US": "It's not related to my question",
            "en-au": "It's not related to my question",
            "en-ca": "It's not related to my question",
            "en-gb": "It's not related to my question",
            "en-my": "It's not related to my question",
            "en-ph": "It's not related to my question",
            "en-se": "It's not related to my question",
            "en-x-dev": "It's not related to my question",
            "en-x-keys": "embeddable_framework.automaticAnswers.desktop.irrelevant.not_related",
            "en-x-obsolete": "It's not related to my question",
            "en-x-pseudo": "[ผู้龍ḬḬṭ'ṡ ṇṓṓṭ ṛḛḛḽααṭḛḛḍ ṭṓṓ ṃẏẏ ʠṵṵḛḛṡṭḭḭṓṓṇ龍ผู้]",
            "en-x-test": "It's not related to my question",
            "es": "No está relacionado con mi pregunta",
            "es-419": "No está relacionado con mi pregunta",
            "es-es": "No está relacionado con mi pregunta",
            "et": "See pole mu küsimusega seotud",
            "eu": "No está relacionado con mi pregunta",
            "fa": "با سؤال من ارتباطی ندارد",
            "fa-AF": "It's not related to my question",
            "fi": "Se ei liity kysymykseeni",
            "fil": "Wala itong kaugnayan sa aking tanong",
            "fo": "It's not related to my question",
            "fr": "Sans rapport avec ma question",
            "fr-ca": "Sans rapport avec ma question",
            "ga": "It's not related to my question",
            "he": "הוא לא קשור לשאלה שלי",
            "hi": "यह मेरे प्रश्न से संबंधित नहीं है",
            "hr": "Nije povezan s mojim pitanjem",
            "hu": "Nem a kérdésemmel kapcsolatos",
            "hy": "It's not related to my question",
            "id": "Tidak terkait dengan pertanyaan saya",
            "is": "Hún tengist ekki spurningunni minni",
            "it": "Non è pertinente alla domanda",
            "it-ch": "Non è pertinente alla domanda",
            "ja": "質問と関係がない",
            "ka": "It's not related to my question",
            "kk": "It's not related to my question",
            "kl-dk": "It's not related to my question",
            "ko": "질문과 관련이 없음",
            "ku": "Ev peywendî digel pirsa me tuneye",
            "lt": "Tai nesusiję su mano klausimu",
            "lv": "Tas nav saistīts ar manu jautājumu",
            "mk": "It's not related to my question",
            "mn": "It's not related to my question",
            "ms": "Ia tidak berkaitan dengan soalan saya",
            "mt": "It's not related to my question",
            "my": "It's not related to my question",
            "nl": "Het artikel heeft geen betrekking op mijn vraag",
            "nl-be": "Het artikel heeft geen betrekking op mijn vraag",
            "no": "Den har ikke noe med spørsmålet mitt å gjøre",
            "pl": "Brak związku z pytaniem",
            "pt": "Não está relacionado à minha pergunta",
            "pt-br": "Não está relacionado à minha pergunta",
            "ro": "Nu are legătură cu întrebarea mea",
            "ru": "Она не относится к моему вопросу",
            "sk": "S mojou otázkou to nesúvisí",
            "sl": "Ni povezano z mojim vprašanjem",
            "sq": "Nuk ka lidhje me pyetjen time",
            "sr": "Ne odnosi se na moje pitanje",
            "sr-me": "It's not related to my question",
            "sv": "Den är inte relaterad till frågan",
            "th": "มันไม่เกี่ยวข้องกับข้อสงสัยของฉัน",
            "tr": "Sorumla ilgili değil",
            "uk": "Вона не стосується мого запитання",
            "ur": "یہ میرے سوال سے متعلق نہیں ہے",
            "uz": "It's not related to my question",
            "vi": "Nó không liên quan đến câu hỏi của tôi",
            "zh-cn": "这和我的问题不相关",
            "zh-tw": "與我的問題無關"
          },
          "question_v3": {
            "af": "Please tell us why.",
            "ar": "يُرجى إخبارنا عن السبب.",
            "ar-x-pseudo": "[ผู้龍Ṕḽḛḛααṡḛḛ ṭḛḛḽḽ ṵṵṡ ẁḥẏẏ.龍ผู้]",
            "az": "Please tell us why.",
            "be": "Сообщите нам причину.",
            "bg": "Споделете защо.",
            "bn": "Please tell us why.",
            "bs": "Please tell us why.",
            "ca": "Digueu-nos el motiu.",
            "cs": "Napište nám proč.",
            "cy": "Please tell us why.",
            "da": "Fortæl os venligst hvorfor.",
            "de": "Bitte nennen Sie den Grund",
            "de-de": "Bitte nennen Sie den Grund",
            "de-x-informal": "Bitte nennen Sie den Grund",
            "el": "Πείτε μας γιατί.",
            "en-001": "Please tell us why.",
            "en-150": "Please tell us why.",
            "en-US": "Please tell us why.",
            "en-au": "Please tell us why.",
            "en-ca": "Please tell us why.",
            "en-gb": "Please tell us why.",
            "en-my": "Please tell us why.",
            "en-ph": "Please tell us why.",
            "en-se": "Please tell us why.",
            "en-x-dev": "Please tell us why.",
            "en-x-keys": "embeddable_framework.automaticAnswers.desktop.irrelevant.question_v3",
            "en-x-obsolete": "Please tell us why.",
            "en-x-pseudo": "[ผู้龍Ṕḽḛḛααṡḛḛ ṭḛḛḽḽ ṵṵṡ ẁḥẏẏ.龍ผู้]",
            "en-x-test": "Please tell us why.",
            "es": "Díganos por qué.",
            "es-419": "Díganos por qué.",
            "es-es": "Díganos por qué.",
            "et": "Öelge meile põhjus.",
            "eu": "Díganos por qué.",
            "fa": "لطفاً دلیل آن را بیان کنید:",
            "fa-AF": "Please tell us why.",
            "fi": "Kerro meille miksi.",
            "fil": "Pakisabi sa amin kung bakit.",
            "fo": "Please tell us why.",
            "fr": "Dites-nous pourquoi.",
            "fr-ca": "Dites-nous pourquoi.",
            "ga": "Please tell us why.",
            "he": "ספר לנו למה.",
            "hi": "कृपया हमें कारण बताएँ.",
            "hr": "Recite nam zašto.",
            "hu": "Mondja el, hogy miért.",
            "hy": "Please tell us why.",
            "id": "Beri tahu kami alasannya.",
            "is": "Segðu okkur hvers vegna.",
            "it": "Spiegaci perché:",
            "it-ch": "Spiegaci perché:",
            "ja": "理由を選んでください。",
            "ka": "Please tell us why.",
            "kk": "Please tell us why.",
            "kl-dk": "Please tell us why.",
            "ko": "이유를 알려 주세요.",
            "ku": "Tikaye sedema wan bi me re bibêje",
            "lt": "Papasakokite, kodėl.",
            "lv": "Lūdzu, atzīmējiet, kāpēc.",
            "mk": "Please tell us why.",
            "mn": "Please tell us why.",
            "ms": "Sila beritahu kami mengapa.",
            "mt": "Please tell us why.",
            "my": "Please tell us why.",
            "nl": "Vertel ons waarom.",
            "nl-be": "Vertel ons waarom.",
            "no": "Fortell oss hvorfor.",
            "pl": "Podaj nam powód.",
            "pt": "Informe os motivos.",
            "pt-br": "Informe os motivos.",
            "ro": "Spuneți-ne motivul.",
            "ru": "Сообщите нам причину.",
            "sk": "Prezraďte nám dôvod, prosím:",
            "sl": "Povejte nam zakaj.",
            "sq": "Na tregoni pse.",
            "sr": "Recite na zbog čega:",
            "sr-me": "Please tell us why.",
            "sv": "Berätta varför.",
            "th": "โปรดบอกเหตุผล",
            "tr": "Lütfen bize nedenini bildirin.",
            "uk": "Вкажіть причину.",
            "ur": "براہ کرم ہمیں بتائیں کیوں:",
            "uz": "Please tell us why.",
            "vi": "Hãy cho chúng tôi biết lý do:",
            "zh-cn": "请说明原因。",
            "zh-tw": "請告知原因。"
          },
          "thanks_for_feedback": {
            "af": "Thanks for your feedback.",
            "ar": "شكراً على رأيك.",
            "ar-x-pseudo": "[ผู้龍Ṫḥααṇḳṡ ϝṓṓṛ ẏẏṓṓṵṵṛ ϝḛḛḛḛḍḅααͼḳ.龍ผู้]",
            "az": "Thanks for your feedback.",
            "be": "Спасибо за отзыв.",
            "bg": "Благодарим за отзива.",
            "bn": "Thanks for your feedback.",
            "bs": "Thanks for your feedback.",
            "ca": "Gràcies pels vostres comentaris.",
            "cs": "Děkujeme za zpětnou vazbu.",
            "cy": "Thanks for your feedback.",
            "da": "Tak for tilbagemeldingen.",
            "de": "Vielen Dank für das Feedback.",
            "de-de": "Vielen Dank für das Feedback.",
            "de-x-informal": "Vielen Dank für das Feedback.",
            "el": "Σας ευχαριστούμε για τις παρατηρήσεις σας.",
            "en-001": "Thanks for your feedback.",
            "en-150": "Thanks for your feedback.",
            "en-US": "Thanks for your feedback.",
            "en-au": "Thanks for your feedback.",
            "en-ca": "Thanks for your feedback.",
            "en-gb": "Thanks for your feedback.",
            "en-my": "Thanks for your feedback.",
            "en-ph": "Thanks for your feedback.",
            "en-se": "Thanks for your feedback.",
            "en-x-dev": "Thanks for your feedback.",
            "en-x-keys": "embeddable_framework.automaticAnswers.desktop.irrelevant.thanks_for_feedback",
            "en-x-obsolete": "Thanks for your feedback.",
            "en-x-pseudo": "[ผู้龍Ṫḥααṇḳṡ ϝṓṓṛ ẏẏṓṓṵṵṛ ϝḛḛḛḛḍḅααͼḳ.龍ผู้]",
            "en-x-test": "Thanks for your feedback.",
            "es": "Le agradecemos estos comentarios.",
            "es-419": "Le agradecemos estos comentarios.",
            "es-es": "Le agradecemos estos comentarios.",
            "et": "Täname tagasiside eest.",
            "eu": "Le agradecemos estos comentarios.",
            "fa": "از بازخورد شما متشکریم.",
            "fa-AF": "Thanks for your feedback.",
            "fi": "Kiitos palautteesta.",
            "fil": "Salamat sa iyong puna.",
            "fo": "Thanks for your feedback.",
            "fr": "Merci de ces commentaires.",
            "fr-ca": "Merci de ces commentaires.",
            "ga": "Thanks for your feedback.",
            "he": "תודה על המשוב.",
            "hi": "आपकी प्रतिक्रिया के लिए धन्यवाद.",
            "hr": "Hvala vam na povratnim informacijama.",
            "hu": "Köszönjük a visszajelzését.",
            "hy": "Thanks for your feedback.",
            "id": "Terima kasih atas umpan balik Anda.",
            "is": "Takk fyrir ábendinguna",
            "it": "Grazie del feedback.",
            "it-ch": "Grazie del feedback.",
            "ja": "貴重なご意見をいただきありがとうございます。",
            "ka": "Thanks for your feedback.",
            "kk": "Thanks for your feedback.",
            "kl-dk": "Thanks for your feedback.",
            "ko": "피드백을 주셔서 감사합니다.",
            "ku": "Spas ji bo bersivên te.",
            "lt": "Ačiū už jūsų atsiliepimą.",
            "lv": "Paldies par atbildi!",
            "mk": "Thanks for your feedback.",
            "mn": "Thanks for your feedback.",
            "ms": "Terima kasih atas maklum balas anda.",
            "mt": "Thanks for your feedback.",
            "my": "Thanks for your feedback.",
            "nl": "Bedankt voor de feedback.",
            "nl-be": "Bedankt voor de feedback.",
            "no": "Tusen takk for tilbakemeldingen.",
            "pl": "Dziękujemy za opinię.",
            "pt": "Agradecemos o feedback.",
            "pt-br": "Agradecemos o feedback.",
            "ro": "Mulțumim pentru feedback.",
            "ru": "Спасибо за отзыв.",
            "sk": "Ďakujeme vám za spätnú väzbu.",
            "sl": "Hvala za vaše povratne informacije.",
            "sq": "Faleminderit për përshtypjet e dhëna.",
            "sr": "Hvala na povratnoj informaciji.",
            "sr-me": "Thanks for your feedback.",
            "sv": "Tack för all feedback.",
            "th": "ขอขอบคุณสำหรับฟีดแบ็ค",
            "tr": "Geri bildiriminiz için teşekkür ederiz.",
            "uk": "Дякуємо за відгук.",
            "ur": "آپ کی رائے کا شکریہ۔",
            "uz": "Thanks for your feedback.",
            "vi": "Cảm ơn bạn đã gửi phản hồi.",
            "zh-cn": "感谢反馈。",
            "zh-tw": "感謝提供意見。"
          },
          "thanks_for_feedback_v2": {
            "af": "Thanks for your feedback",
            "ar": "شكراً على رأيك",
            "ar-x-pseudo": "[ผู้龍Ṫḥααṇḳṡ ϝṓṓṛ ẏẏṓṓṵṵṛ ϝḛḛḛḛḍḅααͼḳ龍ผู้]",
            "az": "Thanks for your feedback",
            "be": "Спасибо за отзыв",
            "bg": "Благодарим за отзива",
            "bn": "Thanks for your feedback",
            "bs": "Thanks for your feedback",
            "ca": "Gràcies pels vostres comentaris",
            "cs": "Děkujeme za zpětnou vazbu",
            "cy": "Thanks for your feedback",
            "da": "Tak for tilbagemeldingen",
            "de": "Vielen Dank für das Feedback",
            "de-de": "Vielen Dank für das Feedback",
            "de-x-informal": "Vielen Dank für das Feedback",
            "el": "Σας ευχαριστούμε για τις παρατηρήσεις",
            "en-001": "Thanks for your feedback",
            "en-150": "Thanks for your feedback",
            "en-US": "Thanks for your feedback",
            "en-au": "Thanks for your feedback",
            "en-ca": "Thanks for your feedback",
            "en-gb": "Thanks for your feedback",
            "en-my": "Thanks for your feedback",
            "en-ph": "Thanks for your feedback",
            "en-se": "Thanks for your feedback",
            "en-x-dev": "Thanks for your feedback",
            "en-x-keys": "embeddable_framework.automaticAnswers.desktop.irrelevant.thanks_for_feedback_v2",
            "en-x-obsolete": "Thanks for your feedback",
            "en-x-pseudo": "[ผู้龍Ṫḥααṇḳṡ ϝṓṓṛ ẏẏṓṓṵṵṛ ϝḛḛḛḛḍḅααͼḳ龍ผู้]",
            "en-x-test": "Thanks for your feedback",
            "es": "Le agradecemos los comentarios",
            "es-419": "Le agradecemos los comentarios",
            "es-es": "Le agradecemos los comentarios",
            "et": "Täname tagasiside eest",
            "eu": "Le agradecemos los comentarios",
            "fa": "از بازخورد شما متشکریم",
            "fa-AF": "Thanks for your feedback",
            "fi": "Kiitos palautteesta",
            "fil": "Salamat sa iyong puna",
            "fo": "Thanks for your feedback",
            "fr": "Merci de ces commentaires.",
            "fr-ca": "Merci de ces commentaires.",
            "ga": "Thanks for your feedback",
            "he": "תודה על המשוב",
            "hi": "आपकी प्रतिक्रिया के लिए धन्यवाद",
            "hr": "Hvala vam na povratnim informacijama",
            "hu": "Köszönjük a visszajelzését",
            "hy": "Thanks for your feedback",
            "id": "Terima kasih atas umpan balik Anda",
            "is": "Takk fyrir ábendinguna",
            "it": "Grazie del feedback.",
            "it-ch": "Grazie del feedback.",
            "ja": "貴重なご意見をいただきありがとうございます",
            "ka": "Thanks for your feedback",
            "kk": "Thanks for your feedback",
            "kl-dk": "Thanks for your feedback",
            "ko": "피드백을 주셔서 감사합니다.",
            "ku": "Spas ji bo bersivên te.",
            "lt": "Ačiū už jūsų atsiliepimą",
            "lv": "Paldies par atbildi!",
            "mk": "Thanks for your feedback",
            "mn": "Thanks for your feedback",
            "ms": "Terima kasih atas maklum balas anda",
            "mt": "Thanks for your feedback",
            "my": "Thanks for your feedback",
            "nl": "Bedankt voor de feedback",
            "nl-be": "Bedankt voor de feedback",
            "no": "Tusen takk for tilbakemeldingen",
            "pl": "Dziękujemy za opinię",
            "pt": "Agradecemos o feedback",
            "pt-br": "Agradecemos o feedback",
            "ro": "Mulțumim pentru feedback",
            "ru": "Спасибо за отзыв",
            "sk": "Ďakujeme vám za spätnú väzbu",
            "sl": "Hvala za vaše povratne informacije",
            "sq": "Faleminderit për përshtypjet e dhëna",
            "sr": "Hvala na povratnoj informaciji",
            "sr-me": "Thanks for your feedback",
            "sv": "Tack för all feedback",
            "th": "ขอขอบคุณสำหรับฟีดแบ็ค",
            "tr": "Geri bildiriminiz için teşekkür ederiz",
            "uk": "Дякуємо за відгук",
            "ur": "آپ کی رائے کا شکریہ",
            "uz": "Thanks for your feedback",
            "vi": "Cảm ơn bạn đã gửi phản hồi",
            "zh-cn": "感谢反馈",
            "zh-tw": "感謝提供意見"
          }
        },
        "solve": {
          "closed": {
            "af": "Nice! Your request has been closed.",
            "ar": "جميل! تم إغلاق الطلب.",
            "ar-x-pseudo": "[ผู้龍Ṅḭḭͼḛḛ! ŶŶṓṓṵṵṛ ṛḛḛʠṵṵḛḛṡṭ ḥααṡ ḅḛḛḛḛṇ ͼḽṓṓṡḛḛḍ.龍ผู้]",
            "az": "Nice! Your request has been closed.",
            "be": "Превосходно! Запрос закрыт.",
            "bg": "Чудесно! Заявката е затворена.",
            "bn": "Nice! Your request has been closed.",
            "bs": "Nice! Your request has been closed.",
            "ca": "Fantàstic! La vostra sol·licitud s’ha tancat.",
            "cs": "Výborně! Požadavek byl uzavřen.",
            "cy": "Nice! Your request has been closed.",
            "da": "Super! Anmodningen er blevet lukket.",
            "de": "Die Anfrage wurde geschlossen.",
            "de-de": "Die Anfrage wurde geschlossen.",
            "de-x-informal": "Die Anfrage wurde geschlossen.",
            "el": "Ωραία! Το αίτημα έχει κλείσει.",
            "en-001": "Nice! Your request has been closed.",
            "en-150": "Nice! Your request has been closed.",
            "en-US": "Nice! Your request has been closed.",
            "en-au": "Nice! Your request has been closed.",
            "en-ca": "Nice! Your request has been closed.",
            "en-gb": "Nice! Your request has been closed.",
            "en-my": "Nice! Your request has been closed.",
            "en-ph": "Nice! Your request has been closed.",
            "en-se": "Nice! Your request has been closed.",
            "en-x-dev": "Nice! Your request has been closed.",
            "en-x-keys": "embeddable_framework.automaticAnswers.desktop.solve.closed",
            "en-x-obsolete": "Nice! Your request has been closed.",
            "en-x-pseudo": "[ผู้龍Ṅḭḭͼḛḛ! ŶŶṓṓṵṵṛ ṛḛḛʠṵṵḛḛṡṭ ḥααṡ ḅḛḛḛḛṇ ͼḽṓṓṡḛḛḍ.龍ผู้]",
            "en-x-test": "Nice! Your request has been closed.",
            "es": "¡Excelente! La solicitud fue cerrada.",
            "es-419": "¡Excelente! La solicitud fue cerrada.",
            "es-es": "¡Excelente! La solicitud fue cerrada.",
            "et": "Suurepärane! Teie päring on suletud.",
            "eu": "¡Excelente! La solicitud fue cerrada.",
            "fa": "عالی! درخواست شما بسته شده است.",
            "fa-AF": "Nice! Your request has been closed.",
            "fi": "Hienoa! Pyyntö on suljettu.",
            "fil": "Magaling! Sarado na ang iyong kahilingan.",
            "fo": "Nice! Your request has been closed.",
            "fr": "Bien ! La demande a été fermée.",
            "fr-ca": "Bien! La demande a été fermée.",
            "ga": "Nice! Your request has been closed.",
            "he": "יפה! הבקשה נסגרה.",
            "hi": "बढ़िया! आपका अनुरोध बंद कर दिया गया है.",
            "hr": "Odlično! Vaš je zahtjev zatvoren.",
            "hu": "Remek! A kérelmét lezárták.",
            "hy": "Nice! Your request has been closed.",
            "id": "Bagus! Permintaan Anda telah ditutup.",
            "is": "Flott! Beiðninni hefur verið lokað.",
            "it": "Ottimo! La richiesta è stata chiusa.",
            "it-ch": "Ottimo! La richiesta è stata chiusa.",
            "ja": "リクエストは終了しました。",
            "ka": "Nice! Your request has been closed.",
            "kk": "Nice! Your request has been closed.",
            "kl-dk": "Nice! Your request has been closed.",
            "ko": "축하합니다! 요청이 종료되었습니다.",
            "ku": "Gelek baş Daxwaza te hatîye daxistin.",
            "lt": "Puiku! Jūsų užklausa uždaryta.",
            "lv": "Jauki! Pieprasījums ir slēgts.",
            "mk": "Nice! Your request has been closed.",
            "mn": "Nice! Your request has been closed.",
            "ms": "Bagus! Permintaan anda telah ditutup.",
            "mt": "Nice! Your request has been closed.",
            "my": "Nice! Your request has been closed.",
            "nl": "Fijn. De aanvraag is gesloten.",
            "nl-be": "Fijn. De aanvraag is gesloten.",
            "no": "Flott! Forespørselen er avsluttet.",
            "pl": "Nieźle! Zlecenie zostało zamknięte.",
            "pt": "Legal! A solicitação foi fechada.",
            "pt-br": "Legal! A solicitação foi fechada.",
            "ro": "Grozav! Solicitarea a fost închisă.",
            "ru": "Превосходно! Запрос закрыт.",
            "sk": "Skvelé! Vaša žiadosť bola uzavretá.",
            "sl": "Lepo! Vaša zahteva je zaprta.",
            "sq": "Bukur! Kërkesa juaj u mbyll.",
            "sr": "Lepo! Vaš zahtev je zatvoren.",
            "sr-me": "Nice! Your request has been closed.",
            "sv": "Utmärkt! Denna förfrågan har stängts.",
            "th": "ยอดเลย! คำร้องขอของคุณปิดลงแล้ว",
            "tr": "Güzel! Talebiniz kapatıldı.",
            "uk": "Чудово! Ваш запит закрито.",
            "ur": "عمدہ! آپ کی درخوات بند کر دی گئی ہے۔",
            "uz": "Nice! Your request has been closed.",
            "vi": "Tuyệt! Yêu cầu đã được đóng lại.",
            "zh-cn": "很好！此请求已关闭。",
            "zh-tw": "太好了！此請求已關閉。"
          },
          "no": {
            "af": "No",
            "ar": "لا",
            "ar-x-pseudo": "[ผู้龍Ṅṓṓ龍ผู้]",
            "az": "No",
            "be": "Нет",
            "bg": "Не",
            "bn": "No",
            "bs": "No",
            "ca": "No",
            "cs": "Ne",
            "cy": "No",
            "da": "Nej",
            "de": "Nein",
            "de-de": "Nein",
            "de-x-informal": "Nein",
            "el": "Όχι",
            "en-001": "No",
            "en-150": "No",
            "en-US": "No",
            "en-au": "No",
            "en-ca": "No",
            "en-gb": "No",
            "en-my": "No",
            "en-ph": "No",
            "en-se": "No",
            "en-x-dev": "No",
            "en-x-keys": "embeddable_framework.automaticAnswers.desktop.solve.no",
            "en-x-obsolete": "No",
            "en-x-pseudo": "[ผู้龍Ṅṓṓ龍ผู้]",
            "en-x-test": "No",
            "es": "No",
            "es-419": "No",
            "es-es": "No",
            "et": "Ei",
            "eu": "No",
            "fa": "خیر",
            "fa-AF": "No",
            "fi": "Ei",
            "fil": "Hindi",
            "fo": "No",
            "fr": "Non",
            "fr-ca": "Non",
            "ga": "No",
            "he": "לא",
            "hi": "नहीं",
            "hr": "Ne",
            "hu": "Nem",
            "hy": "No",
            "id": "Tidak",
            "is": "Nei",
            "it": "No",
            "it-ch": "No",
            "ja": "いいえ",
            "ka": "No",
            "kk": "No",
            "kl-dk": "No",
            "ko": "아니요",
            "ku": "Nexêr",
            "lt": "Ne",
            "lv": "Nē",
            "mk": "No",
            "mn": "No",
            "ms": "Tidak",
            "mt": "No",
            "my": "No",
            "nl": "Nee",
            "nl-be": "Nee",
            "no": "Nei",
            "pl": "Nie",
            "pt": "Não",
            "pt-br": "Não",
            "ro": "Nu",
            "ru": "Нет",
            "sk": "Nie",
            "sl": "Ne",
            "sq": "Jo",
            "sr": "Ne",
            "sr-me": "No",
            "sv": "Nej",
            "th": "ไม่",
            "tr": "Hayır",
            "uk": "Ні",
            "ur": "نہیں",
            "uz": "No",
            "vi": "Không",
            "zh-cn": "否",
            "zh-tw": "否"
          },
          "question": {
            "af": "Does this article answer your question?",
            "ar": "هل يجيب هذا المقال عن سؤالك؟",
            "ar-x-pseudo": "[ผู้龍Ḍṓṓḛḛṡ ṭḥḭḭṡ ααṛṭḭḭͼḽḛḛ ααṇṡẁḛḛṛ ẏẏṓṓṵṵṛ ʠṵṵḛḛṡṭḭḭṓṓṇ?龍ผู้]",
            "az": "Does this article answer your question?",
            "be": "Есть ли в этой статье ответ на ваш вопрос?",
            "bg": "Отговаря ли тази статия на въпроса?",
            "bn": "Does this article answer your question?",
            "bs": "Does this article answer your question?",
            "ca": "Aquest article respon a la vostra pregunta?",
            "cs": "Odpověděl tento článek na vaši otázku?",
            "cy": "Does this article answer your question?",
            "da": "Besvarede denne artikel dit spørgsmål?",
            "de": "Hat dieser Beitrag die Frage beantwortet?",
            "de-de": "Hat dieser Beitrag die Frage beantwortet?",
            "de-x-informal": "Hat dieser Beitrag die Frage beantwortet?",
            "el": "Απαντά στην ερώτησή σας το άρθρο;",
            "en-001": "Does this article answer your question?",
            "en-150": "Does this article answer your question?",
            "en-US": "Does this article answer your question?",
            "en-au": "Does this article answer your question?",
            "en-ca": "Does this article answer your question?",
            "en-gb": "Does this article answer your question?",
            "en-my": "Does this article answer your question?",
            "en-ph": "Does this article answer your question?",
            "en-se": "Does this article answer your question?",
            "en-x-dev": "Does this article answer your question?",
            "en-x-keys": "embeddable_framework.automaticAnswers.desktop.solve.question",
            "en-x-obsolete": "Does this article answer your question?",
            "en-x-pseudo": "[ผู้龍Ḍṓṓḛḛṡ ṭḥḭḭṡ ααṛṭḭḭͼḽḛḛ ααṇṡẁḛḛṛ ẏẏṓṓṵṵṛ ʠṵṵḛḛṡṭḭḭṓṓṇ?龍ผู้]",
            "en-x-test": "Does this article answer your question?",
            "es": "¿Responde la pregunta este artículo?",
            "es-419": "¿Responde la pregunta este artículo?",
            "es-es": "¿Responde la pregunta este artículo?",
            "et": "Kas see artikkel vastas teie küsimusele?",
            "eu": "¿Responde la pregunta este artículo?",
            "fa": "آیا این مقاله به سؤال شما پاسخ می‌دهد؟",
            "fa-AF": "Does this article answer your question?",
            "fi": "Vastaako tämä artikkeli kysymykseen?",
            "fil": "Nasagot ba ng artikulong ito ang iyong tanong?",
            "fo": "Does this article answer your question?",
            "fr": "Cet article répond-il à la question ?",
            "fr-ca": "Cet article répond-il à la question?",
            "ga": "Does this article answer your question?",
            "he": "האם המאמר הזה עונה על השאלה?",
            "hi": "क्या इस आलेख में आपके प्रश्न का उत्तर मिला?",
            "hr": "Je li članak odgovorio na vaše pitanje?",
            "hu": "Ez a cikk választ ad a kérdésére?",
            "hy": "Does this article answer your question?",
            "id": "Apakah artikel ini menjawab pertanyaan Anda?",
            "is": "Svarar þessi grein spurningunni þinni?",
            "it": "Questo articolo risponde alla domanda?",
            "it-ch": "Questo articolo risponde alla domanda?",
            "ja": "この記事で疑問が解消されますか？",
            "ka": "Does this article answer your question?",
            "kk": "Does this article answer your question?",
            "kl-dk": "Does this article answer your question?",
            "ko": "이 문서가 질문에 대한 답이 되었나요?",
            "ku": "Ev bend bersiva pirsên ted ide?",
            "lt": "Ar šiame straipsnyje radote atsakymą į savo klausimą?",
            "lv": "Vai šajā rakstā atradāt atbildi uz savu jautājumu?",
            "mk": "Does this article answer your question?",
            "mn": "Does this article answer your question?",
            "ms": "Adakah artikel ini menjawab soalan anda?",
            "mt": "Does this article answer your question?",
            "my": "Does this article answer your question?",
            "nl": "Beantwoordt dit artikel de vraag?",
            "nl-be": "Beantwoordt dit artikel de vraag?",
            "no": "Fant du svar på spørsmålet i denne artikkelen?",
            "pl": "Czy artykuł dostarczył odpowiedzi na pytanie?",
            "pt": "Esse artigo responde à pergunta?",
            "pt-br": "Esse artigo responde à pergunta?",
            "ro": "Acest articol răspunde la întrebare?",
            "ru": "Есть ли в этой статье ответ на ваш вопрос?",
            "sk": "Poskytol tento článok odpoveď na vašu otázku?",
            "sl": "Je članek odgovoril na vaše vprašanje?",
            "sq": "A i jep ky artikull përgjigje pyetjes suaj?",
            "sr": "Da li ste pronašli odgovor na vaše pitanje u članku?",
            "sr-me": "Does this article answer your question?",
            "sv": "Besvarar denna artikel frågan?",
            "th": "บทความนี้ได้ตอบข้อสงสัยของคุณหรือไม่",
            "tr": "Bu makale sorunuzu yanıtlıyor mu?",
            "uk": "Ви знайшли в цій статті відповідь на своє запитання?",
            "ur": "کیا مضمون آپ کے سوال کا جواب ہے؟",
            "uz": "Does this article answer your question?",
            "vi": "Bài viết này có giải đáp được câu hỏi của bạn không?",
            "zh-cn": "此文章解答了该疑问了吗？",
            "zh-tw": "此文章是否已回答該問題？"
          },
          "subtext": {
            "af": "Let us know and we'll close your request %(requestLink)s.",
            "ar": "نرجو إخبارنا ثم سنغلق الطلب %(requestLink)s.",
            "ar-x-pseudo": "[ผู้龍Ḻḛḛṭ ṵṵṡ ḳṇṓṓẁ ααṇḍ ẁḛḛ'ḽḽ ͼḽṓṓṡḛḛ ẏẏṓṓṵṵṛ ṛḛḛʠṵṵḛḛṡṭ %(ṛḛḛʠṵṵḛḛṡṭḺḭḭṇḳ)ṡ.龍ผู้]",
            "az": "Let us know and we'll close your request %(requestLink)s.",
            "be": "Дайте нам знать, и мы закроем запрос %(requestLink)s.",
            "bg": "Ако потвърдите, ще закрием заявката %(requestLink)s.",
            "bn": "Let us know and we'll close your request %(requestLink)s.",
            "bs": "Let us know and we'll close your request %(requestLink)s.",
            "ca": "Comenteu-nos-ho i tancarem la vostra sol·licitud %(requestLink)s.",
            "cs": "Dejte nám vědět a my zavřeme požadavek %(requestLink)s.",
            "cy": "Let us know and we'll close your request %(requestLink)s.",
            "da": "Giv os besked, og vi vil lukke anmodningen %(requestLink)s.",
            "de": "Teilen Sie es uns mit, damit wir die Anfrage %(requestLink)s schließen können.",
            "de-de": "Teilen Sie es uns mit, damit wir die Anfrage %(requestLink)s schließen können.",
            "de-x-informal": "Teilen Sie es uns mit, damit wir die Anfrage %(requestLink)s schließen können.",
            "el": "Ενημερώστε μας και θα κλείσουμε το αίτημα %(requestLink)s.",
            "en-001": "Let us know and we'll close your request %(requestLink)s.",
            "en-150": "Let us know and we'll close your request %(requestLink)s.",
            "en-US": "Let us know and we'll close your request %(requestLink)s.",
            "en-au": "Let us know and we'll close your request %(requestLink)s.",
            "en-ca": "Let us know and we'll close your request %(requestLink)s.",
            "en-gb": "Let us know and we'll close your request %(requestLink)s.",
            "en-my": "Let us know and we'll close your request %(requestLink)s.",
            "en-ph": "Let us know and we'll close your request %(requestLink)s.",
            "en-se": "Let us know and we'll close your request %(requestLink)s.",
            "en-x-dev": "Let us know and we'll close your request %(requestLink)s.",
            "en-x-keys": "embeddable_framework.automaticAnswers.desktop.solve.subtext",
            "en-x-obsolete": "Let us know and we'll close your request %(requestLink)s.",
            "en-x-pseudo": "[ผู้龍Ḻḛḛṭ ṵṵṡ ḳṇṓṓẁ ααṇḍ ẁḛḛ'ḽḽ ͼḽṓṓṡḛḛ ẏẏṓṓṵṵṛ ṛḛḛʠṵṵḛḛṡṭ %(ṛḛḛʠṵṵḛḛṡṭḺḭḭṇḳ)ṡ.龍ผู้]",
            "en-x-test": "Let us know and we'll close your request %(requestLink)s.",
            "es": "Díganos y cerraremos la solicitud %(requestLink)s.",
            "es-419": "Díganos y cerraremos la solicitud %(requestLink)s.",
            "es-es": "Díganos y cerraremos la solicitud %(requestLink)s.",
            "et": "Andke meile teada ja me sulgeme päringu %(requestLink)s.",
            "eu": "Díganos y cerraremos la solicitud %(requestLink)s.",
            "fa": "به ما اطلاع دهید و ما درخواست %(requestLink)s شما را خواهیم بست.",
            "fa-AF": "Let us know and we'll close your request %(requestLink)s.",
            "fi": "Ilmoita meille, jotta voimme sulkea pyynnön %(requestLink)s.",
            "fil": "Ipaalam sa amin at isasara namin ang iyong hiling %(requestLink)s.",
            "fo": "Let us know and we'll close your request %(requestLink)s.",
            "fr": "Dites-le nous et nous fermerons la demande %(requestLink)s.",
            "fr-ca": "Dites-le nous et nous fermerons la demande %(requestLink)s.",
            "ga": "Let us know and we'll close your request %(requestLink)s.",
            "he": "הודע לנו ונסגור את הבקשה %(requestLink)s.",
            "hi": "हमें बताएं और हम आपके अनुरोध को पूर्ण कर देंगे %(requestLink)s.",
            "hr": "Javite nam i zatvorit ćemo vaš zahtjev %(requestLink)s.",
            "hu": "Tudassa velünk, és lezárjuk kérelmét (%(requestLink)s).",
            "hy": "Let us know and we'll close your request %(requestLink)s.",
            "id": "Beri tahu kami dan akan kami tutup permintaan Anda %(requestLink)s.",
            "is": "Láttu okkur vita og beiðninni verður lokað %(requestLink)s.",
            "it": "Vorremmo saperlo per poter chiudere la richiesta %(requestLink)s.",
            "it-ch": "Vorremmo saperlo per poter chiudere la richiesta %(requestLink)s.",
            "ja": "通知と同時にリクエスト%(requestLink)sをクローズします。",
            "ka": "Let us know and we'll close your request %(requestLink)s.",
            "kk": "Let us know and we'll close your request %(requestLink)s.",
            "kl-dk": "Let us know and we'll close your request %(requestLink)s.",
            "ko": "알려 주시면 %(requestLink)s 요청을 종료하겠습니다.",
            "ku": "Me agahdar bike û em daxwaza te dadixîn %(requestLink)s.",
            "lt": "Informuokite mus ir uždarysime jūsų užklausą %(requestLink)s.",
            "lv": "Informējiet mūs, un mēs slēgsim pieprasījumu %(requestLink)s.",
            "mk": "Let us know and we'll close your request %(requestLink)s.",
            "mn": "Let us know and we'll close your request %(requestLink)s.",
            "ms": "Beritahu kami dan kami akan menutup permintaan anda %(requestLink)s.",
            "mt": "Let us know and we'll close your request %(requestLink)s.",
            "my": "Let us know and we'll close your request %(requestLink)s.",
            "nl": "Laat het ons weten dan kunnen wij aanvraag %(requestLink)s sluiten.",
            "nl-be": "Laat het ons weten dan kunnen wij aanvraag %(requestLink)s sluiten.",
            "no": "Si fra, så skal vi avslutte forespørselen %(requestLink)s.",
            "pl": "Daj nam znać, to zamkniemy zlecenie %(requestLink)s.",
            "pt": "Nos informe e fecharemos a solicitação %(requestLink)s.",
            "pt-br": "Nos informe e fecharemos a solicitação %(requestLink)s.",
            "ro": "Spuneți-ne și vom închide solicitarea %(requestLink)s.",
            "ru": "Дайте нам знать, и мы закроем запрос %(requestLink)s.",
            "sk": "Ak chcete, aby sme uzavreli vašu žiadosť %(requestLink)s, kontaktujte nás.",
            "sl": "Obvestite nas in vašo zahtevo bomo zaprli %(requestLink)s.",
            "sq": "Na njoftoni dhe do ta mbyllim kërkesën tuaj %(requestLink)s.",
            "sr": "Kažite nam i mi ćemo zatvoriti vaš zahtev %(requestLink)s.",
            "sr-me": "Let us know and we'll close your request %(requestLink)s.",
            "sv": "Berätta om den gjorde det, så stänger vi förfrågan %(requestLink)s.",
            "th": "บอกให้เราทราบ แล้วเราจะปิดคำร้องขอของคุณ %(requestLink)s",
            "tr": "Yanıtlıyorsa, talebinizi (%(requestLink)s) kapatacağız.",
            "uk": "Повідомте нас, і ми закриємо ваш запит %(requestLink)s.",
            "ur": "ہمیں بتائیں اور ہم آپ کی درخواست %(requestLink)s بند کر دیں گے۔",
            "uz": "Let us know and we'll close your request %(requestLink)s.",
            "vi": "Hãy cho chúng tôi biết và chúng tôi sẽ đóng yêu cầu %(requestLink)s của bạn lại.",
            "zh-cn": "请告诉我们，我们会关闭此请求 %(requestLink)s。",
            "zh-tw": "請告訴我們，我們將關閉此請求 %(requestLink)s。"
          },
          "subtext_v2": {
            "af": "If it does, we can close your recent request %(requestLink)s",
            "ar": "في هذه الحالة يمكننا إغلاق الطلب الأخير %(requestLink)s",
            "ar-x-pseudo": "[ผู้龍ḬḬϝ ḭḭṭ ḍṓṓḛḛṡ, ẁḛḛ ͼααṇ ͼḽṓṓṡḛḛ ẏẏṓṓṵṵṛ ṛḛḛͼḛḛṇṭ ṛḛḛʠṵṵḛḛṡṭ %(ṛḛḛʠṵṵḛḛṡṭḺḭḭṇḳ)ṡ龍ผู้]",
            "az": "If it does, we can close your recent request %(requestLink)s",
            "be": "Если да, мы можем закрыть запрос %(requestLink)s",
            "bg": "Ако отговаря, можем да затворим заявката %(requestLink)s",
            "bn": "If it does, we can close your recent request %(requestLink)s",
            "bs": "If it does, we can close your recent request %(requestLink)s",
            "ca": "Si és així, podem tancar la vostra sol·licitud %(requestLink)s",
            "cs": "Pokud ano, můžeme uzavřít nedávný požadavek %(requestLink)s",
            "cy": "If it does, we can close your recent request %(requestLink)s",
            "da": "Hvis den gør, kan vi lukke den seneste anmodning %(requestLink)s",
            "de": "Wenn ja, können wir die Anfrage %(requestLink)s schließen.",
            "de-de": "Wenn ja, können wir die Anfrage %(requestLink)s schließen.",
            "de-x-informal": "Wenn ja, können wir die Anfrage %(requestLink)s schließen.",
            "el": "Αν ναι, μπορούμε να κλείσουμε το πρόσφατο αίτημα %(requestLink)s",
            "en-001": "If it does, we can close your recent request %(requestLink)s",
            "en-150": "If it does, we can close your recent request %(requestLink)s",
            "en-US": "If it does, we can close your recent request %(requestLink)s",
            "en-au": "If it does, we can close your recent request %(requestLink)s",
            "en-ca": "If it does, we can close your recent request %(requestLink)s",
            "en-gb": "If it does, we can close your recent request %(requestLink)s",
            "en-my": "If it does, we can close your recent request %(requestLink)s",
            "en-ph": "If it does, we can close your recent request %(requestLink)s",
            "en-se": "If it does, we can close your recent request %(requestLink)s",
            "en-x-dev": "If it does, we can close your recent request %(requestLink)s",
            "en-x-keys": "embeddable_framework.automaticAnswers.desktop.solve.subtext_v2",
            "en-x-obsolete": "If it does, we can close your recent request %(requestLink)s",
            "en-x-pseudo": "[ผู้龍ḬḬϝ ḭḭṭ ḍṓṓḛḛṡ, ẁḛḛ ͼααṇ ͼḽṓṓṡḛḛ ẏẏṓṓṵṵṛ ṛḛḛͼḛḛṇṭ ṛḛḛʠṵṵḛḛṡṭ %(ṛḛḛʠṵṵḛḛṡṭḺḭḭṇḳ)ṡ龍ผู้]",
            "en-x-test": "If it does, we can close your recent request %(requestLink)s",
            "es": "De ser así, podemos cerrar la reciente solicitud %(requestLink)s",
            "es-419": "De ser así, podemos cerrar la reciente solicitud %(requestLink)s",
            "es-es": "De ser así, podemos cerrar la reciente solicitud %(requestLink)s",
            "et": "Kui see nii on, saame sulgeda hiljutise päringu %(requestLink)s.",
            "eu": "De ser así, podemos cerrar la reciente solicitud %(requestLink)s",
            "fa": "اگر اینطور است، می‌توانیم درخواست اخیر %(requestLink)s شما را ببندیم",
            "fa-AF": "If it does, we can close your recent request %(requestLink)s",
            "fi": "Jos se vastaa, voimme sulkea pyynnön %(requestLink)s.",
            "fil": "Kung gayon, maaari naming isara ang iyong kamakailang kahilingan na %(requestLink)s",
            "fo": "If it does, we can close your recent request %(requestLink)s",
            "fr": "S’il y répond, nous pouvons clore la demande %(requestLink)s",
            "fr-ca": "S’il y répond, nous pouvons clore la demande %(requestLink)s",
            "ga": "If it does, we can close your recent request %(requestLink)s",
            "he": "אם כן, נוכל לסגור את בקשה %(requestLink)s ששלחת לאחרונה",
            "hi": "यदि ऐसा है, तो हम आपका हाल ही का अनुरोध बंद कर सकते है %(requestLink)s",
            "hr": "Ako je, možemo zatvoriti vaš nedavni zahtjev %(requestLink)s",
            "hu": "Ha igen, lezárhatjuk a legutóbbi kérelmét (%(requestLink)s)",
            "hy": "If it does, we can close your recent request %(requestLink)s",
            "id": "Jika demikian, kami dapat menutup permintaan Anda baru-baru ini %(requestLink)s",
            "is": "Ef svo er getum við lokað nýlegu beiðninni %(requestLink)s",
            "it": "In caso affermativo, possiamo chiudere la richiesta %(requestLink)s.",
            "it-ch": "In caso affermativo, possiamo chiudere la richiesta %(requestLink)s.",
            "ja": "質問が解決していれば、最新のリクエスト%(requestLink)sを終了します",
            "ka": "If it does, we can close your recent request %(requestLink)s",
            "kk": "If it does, we can close your recent request %(requestLink)s",
            "kl-dk": "If it does, we can close your recent request %(requestLink)s",
            "ko": "그렇다면 최근 요청 %(requestLink)s을(를) 종료할 수 있습니다.",
            "ku": "Ger bi wê awaye bi, em dikarîn dawîn daxwaz te ji dabixîn.",
            "lt": "Jei taip, galime uždaryti jūsų pastarąją užklausą %(requestLink)s",
            "lv": "Ja atbilde ir sniegta, mēs slēgsim pēdējo pieprasījumu %(requestLink)s",
            "mk": "If it does, we can close your recent request %(requestLink)s",
            "mn": "If it does, we can close your recent request %(requestLink)s",
            "ms": "Jika begitu, bolehkah kami menutup permintaan terbaru anda %(requestLink)s",
            "mt": "If it does, we can close your recent request %(requestLink)s",
            "my": "If it does, we can close your recent request %(requestLink)s",
            "nl": "Als dat het geval is, kunnen wij de recente aanvraag %(requestLink)s sluiten",
            "nl-be": "Als dat het geval is, kunnen wij de recente aanvraag %(requestLink)s sluiten",
            "no": "Hvis den gjør det, kan vi avslutte den nylige forespørselen %(requestLink)s",
            "pl": "Jeśli tak, możemy zamknąć zlecenie %(requestLink)s",
            "pt": "Se sim, podemos fechar a solicitação recente %(requestLink)s",
            "pt-br": "Se sim, podemos fechar a solicitação recente %(requestLink)s",
            "ro": "Dacă reușește, putem închide solicitarea recentă %(requestLink)s",
            "ru": "Если да, мы можем закрыть запрос %(requestLink)s",
            "sk": "Ak áno, môžeme vašu poslednú žiadosť %(requestLink)s uzavrieť",
            "sl": "Če se, lahko zapremo vašo nedavno zahtevo %(requestLink)s",
            "sq": "Nëse po, mund ta mbyllim kërkesën tuaj të fundit %(requestLink)s",
            "sr": "Ako ste dobili odgovor, možemo da zatvorimo vaš nedavni zahtev %(requestLink)s",
            "sr-me": "If it does, we can close your recent request %(requestLink)s",
            "sv": "Om den gör det kan vi stänga förfrågan %(requestLink)s",
            "th": "หากใช่ เราจะสามารถปิดคำร้องขอ %(requestLink)s ของคุณได้",
            "tr": "Yanıtlıyorsa, şu son talebinizi kapatabiliriz: %(requestLink)s",
            "uk": "Якщо так, ми можемо закрити ваш нещодавній запит %(requestLink)s",
            "ur": "اگر ایسا ہوتا ہے، تو ہم آپ کی حالیہ درخواست %(requestLink)s کو بند کر سکتے ہیں",
            "uz": "If it does, we can close your recent request %(requestLink)s",
            "vi": "Nếu có, chúng tôi có thể đóng yêu cầu hiện tại %(requestLink)s",
            "zh-cn": "如果是的话，我们将关闭最近的请求 %(requestLink)s",
            "zh-tw": "若是，我們可關閉近期的請求 %(requestLink)s"
          },
          "subtext_v3": {
            "af": "If it does, we can close your recent request.",
            "ar": "في هذه الحالة يمكننا إغلاق الطلب الأخير.",
            "ar-x-pseudo": "[ผู้龍ḬḬϝ ḭḭṭ ḍṓṓḛḛṡ, ẁḛḛ ͼααṇ ͼḽṓṓṡḛḛ ẏẏṓṓṵṵṛ ṛḛḛͼḛḛṇṭ ṛḛḛʠṵṵḛḛṡṭ.龍ผู้]",
            "az": "If it does, we can close your recent request.",
            "be": "Если да, мы можем закрыть запрос.",
            "bg": "Ако отговаря, можем да затворим заявката.",
            "bn": "If it does, we can close your recent request.",
            "bs": "If it does, we can close your recent request.",
            "ca": "De ser así, podemos cerrar su solicitud reciente.",
            "cs": "Pokud ano, můžeme uzavřít váš nedávný požadavek.",
            "cy": "If it does, we can close your recent request.",
            "da": "Hvis den gjorde, kan vi lukke din seneste anmodning.",
            "de": "Wenn ja, können wir Ihre Anfrage schließen.",
            "de-de": "Wenn ja, können wir Ihre Anfrage schließen.",
            "de-x-informal": "Wenn ja, können wir Ihre Anfrage schließen.",
            "el": "Αν ναι, μπορούμε να κλείσουμε το πρόσφατο αίτημά σας.",
            "en-001": "If it does, we can close your recent request.",
            "en-150": "If it does, we can close your recent request.",
            "en-US": "If it does, we can close your recent request.",
            "en-au": "If it does, we can close your recent request.",
            "en-ca": "If it does, we can close your recent request.",
            "en-gb": "If it does, we can close your recent request.",
            "en-my": "If it does, we can close your recent request.",
            "en-ph": "If it does, we can close your recent request.",
            "en-se": "If it does, we can close your recent request.",
            "en-x-dev": "If it does, we can close your recent request.",
            "en-x-keys": "embeddable_framework.automaticAnswers.desktop.solve.subtext_v3",
            "en-x-obsolete": "If it does, we can close your recent request.",
            "en-x-pseudo": "[ผู้龍ḬḬϝ ḭḭṭ ḍṓṓḛḛṡ, ẁḛḛ ͼααṇ ͼḽṓṓṡḛḛ ẏẏṓṓṵṵṛ ṛḛḛͼḛḛṇṭ ṛḛḛʠṵṵḛḛṡṭ.龍ผู้]",
            "en-x-test": "If it does, we can close your recent request.",
            "es": "De ser así, podemos cerrar su solicitud reciente.",
            "es-419": "De ser así, podemos cerrar su solicitud reciente.",
            "es-es": "De ser así, podemos cerrar su solicitud reciente.",
            "et": "If it does, we can close your recent request.",
            "eu": "De ser así, podemos cerrar su solicitud reciente.",
            "fa": "If it does, we can close your recent request.",
            "fa-AF": "If it does, we can close your recent request.",
            "fi": "Jos se niin tekee, voimme sulkea äskettäisen pyyntösi.",
            "fil": "If it does, we can close your recent request.",
            "fo": "If it does, we can close your recent request.",
            "fr": "S’il y répond, nous pouvons clore votre demande récente.",
            "fr-ca": "S’il y répond, nous pouvons clore votre demande récente.",
            "ga": "If it does, we can close your recent request.",
            "he": "אם כן, נוכל לסגור את הבקשה ששלחת לאחרונה.",
            "hi": "अगर ऐसा होता है, तो हम आपके हाल ही के अनुरोध को बंद कर सकते हैं.",
            "hr": "If it does, we can close your recent request.",
            "hu": "Ha igen, lezárhatjuk a legutóbbi kérelmét.",
            "hy": "If it does, we can close your recent request.",
            "id": "Jika demikian, kami dapat menutup permintaan terbaru Anda.",
            "is": "If it does, we can close your recent request.",
            "it": "In caso affermativo, possiamo chiudere la richiesta.",
            "it-ch": "In caso affermativo, possiamo chiudere la richiesta.",
            "ja": "問題が解決した場合は、今回のリクエストを終了します。",
            "ka": "If it does, we can close your recent request.",
            "kk": "If it does, we can close your recent request.",
            "kl-dk": "If it does, we can close your recent request.",
            "ko": "그렇다면 최근 요청을 종료할 수 있습니다.",
            "ku": "If it does, we can close your recent request.",
            "lt": "If it does, we can close your recent request.",
            "lv": "If it does, we can close your recent request.",
            "mk": "If it does, we can close your recent request.",
            "mn": "If it does, we can close your recent request.",
            "ms": "If it does, we can close your recent request.",
            "mt": "If it does, we can close your recent request.",
            "my": "If it does, we can close your recent request.",
            "nl": "Zo ja, dan kunnen wij uw recente aanvraag sluiten.",
            "nl-be": "Zo ja, dan kunnen wij uw recente aanvraag sluiten.",
            "no": "Hvis den gjør det, kan vi avslutte forespørselen du nylig sendte inn.",
            "pl": "Jeśli tak, możemy zamknąć Twoje ostatnie zlecenie.",
            "pt": "Se sim, podemos fechar sua solicitação recente.",
            "pt-br": "Se sim, podemos fechar sua solicitação recente.",
            "ro": "Dacă reușește, putem închide solicitarea recentă.",
            "ru": "Если да, мы можем закрыть запрос.",
            "sk": "Ak áno, môžeme uzavrieť vašu poslednú požiadavku.",
            "sl": "If it does, we can close your recent request.",
            "sq": "If it does, we can close your recent request.",
            "sr": "If it does, we can close your recent request.",
            "sr-me": "If it does, we can close your recent request.",
            "sv": "Om den gör det kan vi stänga din förfrågan.",
            "th": "หากใช่ เราจะสามารถปิดคำร้องขอล่าสุดของคุณได้",
            "tr": "Öyleyse son talebinizi kapatabiliriz",
            "uk": "Якщо так, ми можемо закрити ваш нещодавній запит.",
            "ur": "If it does, we can close your recent request.",
            "uz": "If it does, we can close your recent request.",
            "vi": "Nếu có, chúng tôi có thể đóng yêu cầu hiện tại của bạn.",
            "zh-cn": "如果是的话，我们将关闭最近的请求。",
            "zh-tw": "若是，我們可關閉您近期的請求。"
          },
          "yes": {
            "af": "Yes",
            "ar": "نعم",
            "ar-x-pseudo": "[ผู้龍ŶŶḛḛṡ龍ผู้]",
            "az": "Yes",
            "be": "Да",
            "bg": "Да",
            "bn": "Yes",
            "bs": "Yes",
            "ca": "Sí",
            "cs": "Ano",
            "cy": "Yes",
            "da": "Ja",
            "de": "Ja",
            "de-de": "Ja",
            "de-x-informal": "Ja",
            "el": "Ναι",
            "en-001": "Yes",
            "en-150": "Yes",
            "en-US": "Yes",
            "en-au": "Yes",
            "en-ca": "Yes",
            "en-gb": "Yes",
            "en-my": "Yes",
            "en-ph": "Yes",
            "en-se": "Yes",
            "en-x-dev": "Yes",
            "en-x-keys": "embeddable_framework.automaticAnswers.desktop.solve.yes",
            "en-x-obsolete": "Yes",
            "en-x-pseudo": "[ผู้龍ŶŶḛḛṡ龍ผู้]",
            "en-x-test": "Yes",
            "es": "Sí",
            "es-419": "Sí",
            "es-es": "Sí",
            "et": "Jah",
            "eu": "Sí",
            "fa": "بله",
            "fa-AF": "Yes",
            "fi": "Kyllä",
            "fil": "Oo",
            "fo": "Yes",
            "fr": "Oui",
            "fr-ca": "Oui",
            "ga": "Yes",
            "he": "כן",
            "hi": "हां",
            "hr": "Da",
            "hu": "Igen",
            "hy": "Yes",
            "id": "Ya",
            "is": "Já",
            "it": "Sì",
            "it-ch": "Sì",
            "ja": "はい",
            "ka": "Yes",
            "kk": "Yes",
            "kl-dk": "Yes",
            "ko": "예",
            "ku": "Belê",
            "lt": "Taip",
            "lv": "Jā",
            "mk": "Yes",
            "mn": "Yes",
            "ms": "Ya",
            "mt": "Yes",
            "my": "Yes",
            "nl": "Ja",
            "nl-be": "Ja",
            "no": "Ja",
            "pl": "Tak",
            "pt": "Sim",
            "pt-br": "Sim",
            "ro": "Da",
            "ru": "Да",
            "sk": "Áno",
            "sl": "Da",
            "sq": "Po",
            "sr": "Da",
            "sr-me": "Yes",
            "sv": "Ja",
            "th": "ใช่",
            "tr": "Evet",
            "uk": "Так",
            "ur": "جی ہاں",
            "uz": "Yes",
            "vi": "Có",
            "zh-cn": "是",
            "zh-tw": "是"
          }
        }
      },
      "label": {
        "error_mobile": {
          "af": "There was a problem. Please try again.",
          "ar": "حدثت مشكلة. الرجاء إعادة المحاولة.",
          "ar-x-pseudo": "[ผู้龍Ṫḥḛḛṛḛḛ ẁααṡ αα ṗṛṓṓḅḽḛḛṃ. Ṕḽḛḛααṡḛḛ ṭṛẏẏ ααḡααḭḭṇ.龍ผู้]",
          "az": "There was a problem. Please try again.",
          "be": "Возникла неполадка. Повторите попытку.",
          "bg": "Възникна проблем. Опитайте отново.",
          "bn": "There was a problem. Please try again.",
          "bs": "There was a problem. Please try again.",
          "ca": "S’ha produït un problema. Torneu-ho a provar.",
          "cs": "Došlo k problému. Zkuste to prosím znovu.",
          "cy": "There was a problem. Please try again.",
          "da": "Der opstod et problem. Prøv igen.",
          "de": "Problem aufgetreten. Bitte versuchen Sie es erneut.",
          "de-de": "Problem aufgetreten. Bitte versuchen Sie es erneut.",
          "de-x-informal": "Problem aufgetreten. Bitte versuchen Sie es erneut.",
          "el": "Υπήρξε κάποιο πρόβλημα. Προσπαθήστε ξανά.",
          "en-001": "There was a problem. Please try again.",
          "en-150": "There was a problem. Please try again.",
          "en-US": "There was a problem. Please try again.",
          "en-au": "There was a problem. Please try again.",
          "en-ca": "There was a problem. Please try again.",
          "en-gb": "There was a problem. Please try again.",
          "en-my": "There was a problem. Please try again.",
          "en-ph": "There was a problem. Please try again.",
          "en-se": "There was a problem. Please try again.",
          "en-x-dev": "There was a problem. Please try again.",
          "en-x-keys": "embeddable_framework.automaticAnswers.label.error_mobile",
          "en-x-obsolete": "There was a problem. Please try again.",
          "en-x-pseudo": "[ผู้龍Ṫḥḛḛṛḛḛ ẁααṡ αα ṗṛṓṓḅḽḛḛṃ. Ṕḽḛḛααṡḛḛ ṭṛẏẏ ααḡααḭḭṇ.龍ผู้]",
          "en-x-test": "There was a problem. Please try again.",
          "es": "Hubo un problema. Vuelva a intentarlo.",
          "es-419": "Hubo un problema. Vuelva a intentarlo.",
          "es-es": "Hubo un problema. Vuelva a intentarlo.",
          "et": "Tekkis tõrge. Proovige uuesti.",
          "eu": "Hubo un problema. Vuelva a intentarlo.",
          "fa": "مشکلی رخ داد. لطفاً دوباره امتحان کنید.",
          "fa-AF": "There was a problem. Please try again.",
          "fi": "Esiintyi ongelma. Yritä uudestaan.",
          "fil": "Nagkaroon ng problema. Pakisubukang muli.",
          "fo": "There was a problem. Please try again.",
          "fr": "Un problème est survenu. Réessayez.",
          "fr-ca": "Un problème est survenu. Réessayez.",
          "ga": "There was a problem. Please try again.",
          "he": "אירעה תקלה כלשהי. נסה שוב.",
          "hi": "कोई समस्या थी. कृपया पुन: प्रयास करें.",
          "hr": "Došlo je do problema. Pokušajte ponovno.",
          "hu": "Probléma merült fel. Kérjük, próbálkozzon újra.",
          "hy": "There was a problem. Please try again.",
          "id": "Ada masalah. Silakan coba lagi.",
          "is": "Vandamál kom upp. Reyndu aftur.",
          "it": "Si è verificato un problema. Riprova.",
          "it-ch": "Si è verificato un problema. Riprova.",
          "ja": "問題がありました。もう一度やり直してください。",
          "ka": "There was a problem. Please try again.",
          "kk": "There was a problem. Please try again.",
          "kl-dk": "There was a problem. Please try again.",
          "ko": "문제가 발생했습니다. 다시 시도하세요.",
          "ku": "Sedemek heye. Tikaye careke din bicerîbîne.",
          "lt": "Kilo problema. Bandykite dar kartą.",
          "lv": "Radās problēma. Lūdzu, mēģiniet vēlreiz.",
          "mk": "There was a problem. Please try again.",
          "mn": "There was a problem. Please try again.",
          "ms": "Terdapat masalah. Sila cuba sekali lagi.",
          "mt": "There was a problem. Please try again.",
          "my": "There was a problem. Please try again.",
          "nl": "Er is een probleem opgetreden. Probeer het opnieuw.",
          "nl-be": "Er is een probleem opgetreden. Probeer het opnieuw.",
          "no": "Det oppstod et problem. Prøv på nytt.",
          "pl": "Wystąpił problem. Spróbuj ponownie.",
          "pt": "Ocorreu um problema. Tente novamente.",
          "pt-br": "Ocorreu um problema. Tente novamente.",
          "ro": "A existat o problemă. Vă rugăm să încercați din nou.",
          "ru": "Возникла неполадка. Повторите попытку.",
          "sk": "Vyskytol sa problém. Skúste to znova.",
          "sl": "Pojavila se je težava. Poskusite znova.",
          "sq": "Pati një problem. Ju lutemi provoni përsëri.",
          "sr": "Došlo je do problema. Pokušajte ponovo.",
          "sr-me": "There was a problem. Please try again.",
          "sv": "Ett problem inträffade. Försök igen.",
          "th": "เกิดปัญหาขึ้น โปรดลองอีกครั้ง",
          "tr": "Bir sorun oluştu. Lütfen tekrar deneyin.",
          "uk": "Сталася помилка. Спробуйте ще раз.",
          "ur": "ایک مسئلہ تھا۔ براہ کرم دوبارہ کوشش کریں۔",
          "uz": "There was a problem. Please try again.",
          "vi": "Đã xảy ra vấn đề. Vui lòng thử lại.",
          "zh-cn": "出现了问题。请重试。",
          "zh-tw": "出現問題。請重試。"
        },
        "prompt_mobile": {
          "af": "Does this article answer your question?",
          "ar": "هل يجيب هذا المقال على الاستفسار؟",
          "ar-x-pseudo": "[ผู้龍Ḍṓṓḛḛṡ ṭḥḭḭṡ ααṛṭḭḭͼḽḛḛ ααṇṡẁḛḛṛ ẏẏṓṓṵṵṛ ʠṵṵḛḛṡṭḭḭṓṓṇ?龍ผู้]",
          "az": "Does this article answer your question?",
          "be": "Есть ли в этой статье ответ на ваш вопрос?",
          "bg": "Отговаря ли тази статия на въпроса?",
          "bn": "Does this article answer your question?",
          "bs": "Does this article answer your question?",
          "ca": "Aquest article soluciona el vostre dubte?",
          "cs": "Odpověděl tento článek na vaši otázku?",
          "cy": "Does this article answer your question?",
          "da": "Besvarer denne artikel spørgsmålet?",
          "de": "Hat dieser Beitrag die Frage beantwortet?",
          "de-de": "Hat dieser Beitrag die Frage beantwortet?",
          "de-x-informal": "Hat dieser Beitrag die Frage beantwortet?",
          "el": "Απαντά στην ερώτησή σας το άρθρο;",
          "en-001": "Does this article answer your question?",
          "en-150": "Does this article answer your question?",
          "en-US": "Does this article answer your question?",
          "en-au": "Does this article answer your question?",
          "en-ca": "Does this article answer your question?",
          "en-gb": "Does this article answer your question?",
          "en-my": "Does this article answer your question?",
          "en-ph": "Does this article answer your question?",
          "en-se": "Does this article answer your question?",
          "en-x-dev": "Does this article answer your question?",
          "en-x-keys": "embeddable_framework.automaticAnswers.label.prompt_mobile",
          "en-x-obsolete": "Does this article answer your question?",
          "en-x-pseudo": "[ผู้龍Ḍṓṓḛḛṡ ṭḥḭḭṡ ααṛṭḭḭͼḽḛḛ ααṇṡẁḛḛṛ ẏẏṓṓṵṵṛ ʠṵṵḛḛṡṭḭḭṓṓṇ?龍ผู้]",
          "en-x-test": "Does this article answer your question?",
          "es": "¿Responde la pregunta este artículo?",
          "es-419": "¿Responde la pregunta este artículo?",
          "es-es": "¿Responde la pregunta este artículo?",
          "et": "Kas see artikkel vastas teie küsimusele?",
          "eu": "¿Responde la pregunta este artículo?",
          "fa": "آیا جواب شما در این مقاله داده شده است؟",
          "fa-AF": "Does this article answer your question?",
          "fi": "Vastaako tämä artikkeli kysymykseen?",
          "fil": "Nasagot ba ng artikulong ito ang iyong tanong?",
          "fo": "Does this article answer your question?",
          "fr": "Cet article répond-il à la question ?",
          "fr-ca": "Cet article répond-il à la question?",
          "ga": "Does this article answer your question?",
          "he": "האם המאמר הזה עונה על השאלה?",
          "hi": "क्या इस लेख में आपके प्रश्न का उत्तर मिला?",
          "hr": "Je li ovaj članak odgovorio na vaše pitanje?",
          "hu": "Ez a cikk választ ad a kérdésére?",
          "hy": "Does this article answer your question?",
          "id": "Apakah artikel ini menjawab pertanyaan Anda?",
          "is": "Svarar þessi grein spurningunni þinni?",
          "it": "Questo articolo risponde alla domanda?",
          "it-ch": "Questo articolo risponde alla domanda?",
          "ja": "この記事で疑問が解消されますか？",
          "ka": "Does this article answer your question?",
          "kk": "Does this article answer your question?",
          "kl-dk": "Does this article answer your question?",
          "ko": "이 도움말이 질문에 대한 답이 되었나요?",
          "ku": "Ev bend bersiva pirsên ted ide?",
          "lt": "Ar šiame straipsnyje radote atsakymą į savo klausimą?",
          "lv": "Vai šajā rakstā atradāt atbildi uz savu jautājumu?",
          "mk": "Does this article answer your question?",
          "mn": "Does this article answer your question?",
          "ms": "Adakah artikel ini menjawab soalan anda?",
          "mt": "Does this article answer your question?",
          "my": "Does this article answer your question?",
          "nl": "Beantwoordt dit artikel de vraag?",
          "nl-be": "Beantwoordt dit artikel de vraag?",
          "no": "Fant du svar på spørsmålet i denne artikkelen?",
          "pl": "Czy ten artykuł zawiera odpowiedź na pytanie?",
          "pt": "Esse artigo responde à pergunta?",
          "pt-br": "Esse artigo responde à pergunta?",
          "ro": "Acest articol răspunde la întrebare?",
          "ru": "Есть ли в этой статье ответ на ваш вопрос?",
          "sk": "Poskytol tento článok odpoveď na vašu otázku?",
          "sl": "Je članek odgovoril na vaše vprašanje?",
          "sq": "A i jep ky artikull përgjigje pyetjes tuaj?",
          "sr": "Da li ste pronašli odgovor na vaše pitanje u članku?",
          "sr-me": "Does this article answer your question?",
          "sv": "Besvarar denna artikel frågan?",
          "th": "บทความนี้ได้ตอบข้อสงสัยของคุณหรือไม่",
          "tr": "Bu makale soruyu yanıtlıyor mu?",
          "uk": "Ви знайшли в цій статті відповідь на своє запитання?",
          "ur": "کیا اس مضمون میں آپ کے سوال کا جواب ہے؟",
          "uz": "Does this article answer your question?",
          "vi": "Bài viết này có giải đáp được thắc mắc của bạn không?",
          "zh-cn": "此文章解答了该疑问了吗？",
          "zh-tw": "此文章是否已回答該問題？"
        }
      },
      "title": {
        "af": "Feedback for article suggestion",
        "ar": "تقييم لمقترح المقال",
        "ar-x-pseudo": "[ผู้龍Ḟḛḛḛḛḍḅααͼḳ ϝṓṓṛ ααṛṭḭḭͼḽḛḛ ṡṵṵḡḡḛḛṡṭḭḭṓṓṇ龍ผู้]",
        "az": "Feedback for article suggestion",
        "be": "Отзыв по предложенной статье",
        "bg": "Отзив за предложените статии",
        "bn": "Feedback for article suggestion",
        "bs": "Feedback for article suggestion",
        "ca": "Comentarios para la sugerencia de artículos",
        "cs": "Zpětná vazba k návrhu článku",
        "cy": "Feedback for article suggestion",
        "da": "Feedback på artikelforslag",
        "de": "Feedback für Beitragsvorschlag",
        "de-de": "Feedback für Beitragsvorschlag",
        "de-x-informal": "Feedback für Beitragsvorschlag",
        "el": "Παρατηρήσεις για πρόταση άρθρου",
        "en-001": "Feedback for article suggestion",
        "en-150": "Feedback for article suggestion",
        "en-US": "Feedback for article suggestion",
        "en-au": "Feedback for article suggestion",
        "en-ca": "Feedback for article suggestion",
        "en-gb": "Feedback for article suggestion",
        "en-my": "Feedback for article suggestion",
        "en-ph": "Feedback for article suggestion",
        "en-se": "Feedback for article suggestion",
        "en-x-dev": "Feedback for article suggestion",
        "en-x-keys": "embeddable_framework.automaticAnswers.title",
        "en-x-obsolete": "Feedback for article suggestion",
        "en-x-pseudo": "[ผู้龍Ḟḛḛḛḛḍḅααͼḳ ϝṓṓṛ ααṛṭḭḭͼḽḛḛ ṡṵṵḡḡḛḛṡṭḭḭṓṓṇ龍ผู้]",
        "en-x-test": "Feedback for article suggestion",
        "es": "Comentarios para la sugerencia de artículos",
        "es-419": "Comentarios para la sugerencia de artículos",
        "es-es": "Comentarios para la sugerencia de artículos",
        "et": "Feedback for article suggestion",
        "eu": "Comentarios para la sugerencia de artículos",
        "fa": "Feedback for article suggestion",
        "fa-AF": "Feedback for article suggestion",
        "fi": "Artikkeliehdotuspalaute",
        "fil": "Feedback for article suggestion",
        "fo": "Feedback for article suggestion",
        "fr": "Commentaires pour la suggestion d’articles",
        "fr-ca": "Commentaires pour la suggestion d’articles",
        "ga": "Feedback for article suggestion",
        "he": "משוב על הצעת מאמר",
        "hi": "आलेख संबंधी सुझाव के लिए प्रतिक्रिया",
        "hr": "Feedback for article suggestion",
        "hu": "Visszajelzés cikkjavaslattal kapcsolatban",
        "hy": "Feedback for article suggestion",
        "id": "Umpan balik untuk saran artikel",
        "is": "Feedback for article suggestion",
        "it": "Feedback per un articolo suggerito",
        "it-ch": "Feedback per un articolo suggerito",
        "ja": "提案記事に対するフィードバック",
        "ka": "Feedback for article suggestion",
        "kk": "Feedback for article suggestion",
        "kl-dk": "Feedback for article suggestion",
        "ko": "문서 추천을 위한 피드백",
        "ku": "Feedback for article suggestion",
        "lt": "Feedback for article suggestion",
        "lv": "Feedback for article suggestion",
        "mk": "Feedback for article suggestion",
        "mn": "Feedback for article suggestion",
        "ms": "Feedback for article suggestion",
        "mt": "Feedback for article suggestion",
        "my": "Feedback for article suggestion",
        "nl": "Feedback voor artikelsuggestie",
        "nl-be": "Feedback voor artikelsuggestie",
        "no": "Tilbakemelding for artikkelforslag",
        "pl": "Informacje zwrotne dotyczące propozycji artykułu",
        "pt": "Feedback para sugestão de artigo",
        "pt-br": "Feedback para sugestão de artigo",
        "ro": "Feedback pentru articolul sugerat",
        "ru": "Отзыв по предложенной статье",
        "sk": "Spätná väzba na návrh článku",
        "sl": "Feedback for article suggestion",
        "sq": "Feedback for article suggestion",
        "sr": "Feedback for article suggestion",
        "sr-me": "Feedback for article suggestion",
        "sv": "Feedback för artikelförslag",
        "th": "ฟีดแบ็คสำหรับคำแนะนำบทความ",
        "tr": "Makale önerisi için geri bildirim",
        "uk": "Відгук на пропозицію статті",
        "ur": "Feedback for article suggestion",
        "uz": "Feedback for article suggestion",
        "vi": "Phản hồi cho gợi ý bài viết",
        "zh-cn": "对于文章建议的反馈",
        "zh-tw": "文章建議的意見"
      }
    }
  },
  "rtl": {
    "af": false,
    "ar": true,
    "ar-x-pseudo": true,
    "az": false,
    "be": false,
    "bg": false,
    "bn": false,
    "bs": false,
    "ca": false,
    "cs": false,
    "cy": false,
    "da": false,
    "de": false,
    "de-de": false,
    "de-x-informal": false,
    "el": false,
    "en-001": false,
    "en-150": false,
    "en-US": false,
    "en-au": false,
    "en-ca": false,
    "en-gb": false,
    "en-my": false,
    "en-ph": false,
    "en-se": false,
    "en-x-dev": false,
    "en-x-keys": false,
    "en-x-obsolete": false,
    "en-x-pseudo": false,
    "en-x-test": false,
    "es": false,
    "es-419": false,
    "es-es": false,
    "et": false,
    "eu": false,
    "fa": true,
    "fa-AF": true,
    "fi": false,
    "fil": false,
    "fo": false,
    "fr": false,
    "fr-ca": false,
    "ga": false,
    "he": true,
    "hi": false,
    "hr": false,
    "hu": false,
    "hy": false,
    "id": false,
    "is": false,
    "it": false,
    "it-ch": false,
    "ja": false,
    "ka": false,
    "kk": false,
    "kl-dk": false,
    "ko": false,
    "ku": false,
    "lt": false,
    "lv": false,
    "mk": false,
    "mn": false,
    "ms": false,
    "mt": false,
    "my": false,
    "nl": false,
    "nl-be": false,
    "no": false,
    "pl": false,
    "pt": false,
    "pt-br": false,
    "ro": false,
    "ru": false,
    "sk": false,
    "sl": false,
    "sq": false,
    "sr": false,
    "sr-me": false,
    "sv": false,
    "th": false,
    "tr": false,
    "uk": false,
    "ur": true,
    "uz": false,
    "vi": false,
    "zh-cn": false,
    "zh-tw": false
  },
  "locales": [
    "af",
    "ar",
    "ar-x-pseudo",
    "az",
    "be",
    "bg",
    "bn",
    "bs",
    "ca",
    "cs",
    "cy",
    "da",
    "de",
    "de-de",
    "de-x-informal",
    "el",
    "en-001",
    "en-150",
    "en-US",
    "en-au",
    "en-ca",
    "en-gb",
    "en-my",
    "en-ph",
    "en-se",
    "en-x-dev",
    "en-x-keys",
    "en-x-obsolete",
    "en-x-pseudo",
    "en-x-test",
    "es",
    "es-419",
    "es-es",
    "et",
    "eu",
    "fa",
    "fa-AF",
    "fi",
    "fil",
    "fo",
    "fr",
    "fr-ca",
    "ga",
    "he",
    "hi",
    "hr",
    "hu",
    "hy",
    "id",
    "is",
    "it",
    "it-ch",
    "ja",
    "ka",
    "kk",
    "kl-dk",
    "ko",
    "ku",
    "lt",
    "lv",
    "mk",
    "mn",
    "ms",
    "mt",
    "my",
    "nl",
    "nl-be",
    "no",
    "pl",
    "pt",
    "pt-br",
    "ro",
    "ru",
    "sk",
    "sl",
    "sq",
    "sr",
    "sr-me",
    "sv",
    "th",
    "tr",
    "uk",
    "ur",
    "uz",
    "vi",
    "zh-cn",
    "zh-tw"
  ]
}