import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import defaultsDeep from 'lodash.defaultsdeep';

import { log } from 'utility/log';
import { EmbedWrapper } from 'component/frame/EmbedWrapper';
import { i18n } from 'service/i18n';
import { transitionFactory } from 'service/transitionFactory';
import { isMobileBrowser } from 'utility/devices';
import { cssTimeToMs } from 'utility/utils';
import mainCSS from 'styles/main.scss';

const baseFontCSS = 'html { font-size: 12px }';

export const frameFactory = function (childFn, _params) {
  let child;

  const isMobile = isMobileBrowser();
  const defaultParams = {
    frameStyle: {
      marginTop: 0,
    },
    css: '',
    onShow: () => {},
    onHide: () => {},
    onClose: () => {},
    onBack: () => {},
    afterShowAnimate: () => {},
    transitions: {},
    isMobile,
    disableSetOffsetHorizontal: false,
    offsetWidth: 15,
    offsetHeight: 15,
    position: 'right',
    preventClose: false,
  };
  const params = defaultsDeep({}, _params, defaultParams);
  const defaultHideTransition = transitionFactory.webWidget.downHide();
  const defaultShowTransition = transitionFactory.webWidget.downShow();

  class Frame extends Component {
    constructor(props, context) {
      super(props, context);

      this.iframe = null;

      log.debug('Frame:constructor');

      this.state = {
        visible: this.props.visible,
        frameStyle: params.frameStyle,
        hiddenByZoom: false,
        _rendered: false,
        iframeDimensions: {
          height: 0,
          width: 0,
        },
      };
    }

    componentDidMount = () => {
      log.debug('Frame:componentDidMount');

      this.renderFrameContent();
    };

    componentDidUpdate = () => {
      log.debug('Frame:componentDidUpdate');

      this.renderFrameContent();
    };

    componentWillUnmount = () => {
      log.debug('Frame:componentWillUnmount');

      ReactDOM.unmountComponentAtNode(this.iframe.contentDocument.body);
    };

    getChild = () => {
      return child;
    };

    getRootComponent = () => {
      if (child) {
        const rootComponent = child.refs.rootComponent;

        return rootComponent.getWrappedInstance
          ? rootComponent.getWrappedInstance()
          : rootComponent;
      }
    };

    setOffsetHorizontal = (offsetValue = 0) => {
      if (!params.disableSetOffsetHorizontal) {
        const frameStyle = Object.assign(this.state.frameStyle, {
          marginLeft: `${offsetValue}px`,
          marginRight: `${offsetValue}px`,
        });

        this.setState({ frameStyle });
      }
    };

    updateFrameSize = () => {
      log.debug('Frame:updateFrameSize');

      const frameDoc = this.iframe.contentDocument;

      if (!frameDoc.firstChild) {
        return false;
      }

      const getDimensions = () => {
        const embedEl = frameDoc.querySelector('#answer-bot-embed');

        if (!embedEl) {
          return {};
        }

        const el = embedEl.firstChild;

        const width = Math.max(el.clientWidth, el.offsetWidth);
        const height = Math.max(el.clientHeight, el.offsetHeight);

        const popoverStyle = {
          width: (Number.isFinite(width) ? width : 0) + params.offsetWidth,
          height: (Number.isFinite(height) ? height : 0) + params.offsetHeight,
        };

        // Set a full width frame with a dynamic height
        if (params.fullWidth) {
          return {
            width: '100%',
            height:
              (Number.isFinite(height) ? height : 0) + params.offsetHeight,
          };
        }

        return popoverStyle;
      };

      const dimensions = getDimensions();

      this.setState({ iframeDimensions: dimensions });

      return dimensions;
    };

    updateBaseFontSize = (fontSize) => {
      const htmlElem = this.iframe.contentDocument.documentElement;

      htmlElem.style.fontSize = fontSize;
    };

    show = (options = {}) => {
      const transition =
        params.transitions[options.transition] || defaultShowTransition;
      const animateFrom = { ...this.state.frameStyle, ...transition.start };
      const animateTo = { ...this.state.frameStyle, ...transition.end };

      this.setState({ visible: true, frameStyle: animateFrom });

      setTimeout(() => this.setState({ frameStyle: animateTo }), 0);

      setTimeout(
        () => params.afterShowAnimate(this),
        cssTimeToMs(transition.end.transitionDuration)
      );

      params.onShow(this);
    };

    hide = (options = {}) => {
      const transition =
        params.transitions[options.transition] || defaultHideTransition;
      const frameStyle = { ...this.state.frameStyle, ...transition.end };

      this.setState({ frameStyle });

      setTimeout(() => {
        this.setState({ visible: false });
        params.onHide(this);
      }, cssTimeToMs(transition.end.transitionDuration));
    };

    close = (ev, options = {}) => {
      if (params.preventClose) return;

      this.hide({ transition: 'downHide' });

      params.onClose(this, options);
    };

    computeIframeStyle = () => {
      const iframeDimensions = this.state.iframeDimensions;
      const visibilityRule =
        this.state.visible && !this.state.hiddenByZoom
          ? null
          : transitionFactory.hiddenState(iframeDimensions.height, false);

      return {
        ...{
          border: 'none',
          background: 'transparent',
          transform: 'translateZ(0)',
          position: 'fixed',
          opacity: 1,
        },
        ...this.state.frameStyle,
        ...iframeDimensions,
        ...visibilityRule,
      };
    };

    constructEmbed = (html, doc) => {
      const position = this.props.position;
      const cssText = mainCSS + params.css + baseFontCSS;
      const fullscreen = params.fullscreenable && params.isMobile;
      const positionClasses = classNames({
        'u-borderTransparent u-posRelative': !fullscreen,
        'u-pullRight': position === 'right',
        'u-pullLeft': position === 'left',
        'u-noPrint': !fullscreen,
      });

      // Callbacks to be passed down to child component
      const childParams = {
        updateFrameSize: this.updateFrameSize,
        setOffsetHorizontal: this.setOffsetHorizontal,
        closeFrame: this.close,
      };

      const element = doc.body.appendChild(doc.createElement('div'));

      element.className = positionClasses;

      // Render a react component into an iframe which is really a react component
      // ReactDOM.render is asynchrounous and you can't rely on the return value
      // this is why there is a callback ref to set the `child`
      ReactDOM.render(
        <EmbedWrapper
          ref={(el) => (child = el)}
          baseCSS={cssText}
          handleBackClick={this.back}
          handleCloseClick={this.close}
          hideCloseButton={params.hideCloseButton}
          childFn={childFn}
          childParams={childParams}
          fullscreen={fullscreen}
        />,
        element
      );

      this.setState({ _rendered: true });

      // This needs to happen in the next tick to make sure the iframe content is rendered
      setTimeout(() => {
        this.updateFrameSize();
      });
    };

    updateFrameLocale = () => {
      const html = this.iframe.contentDocument.documentElement;
      const direction = i18n.isRTL() ? 'rtl' : 'ltr';
      const child = this.getChild();

      html.setAttribute('lang', i18n.getLocale());
      html.setAttribute('dir', direction);

      if (child) child.forceUpdate();
    };

    renderFrameContent = (force = false) => {
      if (this.state._rendered && !force) {
        return false;
      }

      const html = this.iframe.contentDocument.documentElement;
      const doc = this.iframe.contentWindow.document;

      // In order for iframe to correctly render in some browsers we need to do it on nextTick

      /**
        This is commented out because `doc.readyState` never changes to `complete` when running the tests in `jest`.
        Not sure why we need to wait for the `iframe` to be `complete` as well as it doesn't make sense. It's not
        using a `src` attribute to load anything.
        The better way of doing this is to use a state value along with `onLoad` callback on the `iframe` and `setState({ iframeReady: true })`
        More information on https://developer.mozilla.org/en-US/docs/Web/API/Document/readyState
       */

      // if (doc.readyState === 'complete') {
      this.updateFrameLocale();
      this.constructEmbed(html, doc);
      // } else {
      // setTimeout(this.renderFrameContent, 0);
      // }
    };

    render = () => {
      const iframeNamespace = 'zEWidget';
      const iframeTitle = i18n.t('embeddable_framework.automaticAnswers.title');
      const iframeClasses = classNames({
        [`${iframeNamespace}-${params.name}`]: true,
        [`${iframeNamespace}-${params.name}--active`]: this.state.visible,
      });
      const tabIndex = this.state.visible ? '0' : '-1';

      return (
        <iframe
          src="about:blank"
          data-testid="abe-iframe"
          onLoad={() => this.renderFrameContent(true)} // Chrome doesnt fire
          ref={(el) => (this.iframe = el)}
          style={this.computeIframeStyle()}
          id={params.name}
          className={iframeClasses}
          title={iframeTitle}
          tabIndex={tabIndex}
          aria-hidden={!this.state.visible}
        />
      );
    };
  }

  Frame.propTypes = {
    fullscreen: PropTypes.bool,
    visible: PropTypes.bool,
    position: PropTypes.string,
  };

  Frame.defaultProps = {
    fullscreen: false,
    visible: true,
    position: 'right',
  };

  return Frame;
};
